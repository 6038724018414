import React, { Component, Fragment } from 'react'
import qs from 'query-string'
import { subSeconds } from 'date-fns'
import ReportsView from '../views/ReportsView'
import Container from '../views/Container'
import { isOnline } from '../lib/utils/network'
import { timespanStringToSeconds } from '../lib/utils/time'
import Navbar from '../components/Navbar'
import ViewSwitcher from "../components/ViewSwitcher"
import TimeSwitcher from "../components/TimeSwitcher"
import LoadingView from '../views/LoadingView'
import Context from '../models/Context'
// import clockIcon from '../assets/iconic/clock.svg'


import { ReactComponent as PlusIcon } from "../assets/plus.svg"

class ReportsScreen extends Component {
  constructor(props) {
    super(props)

    // this.state = {
    //   showList: false
    // }

    this.onReportSelected = this.onReportSelected.bind(this)
    this.renderNavbarHeaderLeft = this.renderNavbarHeaderLeft.bind(this)
    this.renderNavbarHeaderRight = this.renderNavbarHeaderRight.bind(this)
    this.onClickSwitch = this.onClickSwitch.bind(this)
    this.onClickPlus = this.onClickPlus.bind(this)
    this.onClickHistory = this.onClickHistory.bind(this)
    this.onClickCog = this.onClickCog.bind(this)
    this.onWarningLayerToggle = this.onWarningLayerToggle.bind(this)
  }

  render() {
    const { state } = this.context
    const { showList } = state
    // const { showList } = this.state
    return (
      <Context.Consumer>
        {(context) => {
          const {
            state,
            fetchReports,
            fetchWarnings,
            regionService, setRegion,
            setFromDate, setToDate,
            setPlaybackTime,
            setShowMovie,
            setTimespan,
            setUserLocation,
            t,
            reportPresentation,
            className,
          } = context

          const { reports, region, fromDate, toDate, playbackTime, showHistory,
            showMovie, finishedLoadingRegion, timespan, locale, warningStatusVisible } = state

          let containerClass = 'liquid-container--flexible-height'
          if (showList) {
            containerClass = 'container mx-auto px-4 pt-28 md:pt-32'
          }

          return (
            <div className={`reports-screen ${className || ''}`}>
              <Navbar
                className="app__navbar"
                renderHeaderLeft={this.renderNavbarHeaderLeft}
                renderHeaderRight={this.renderNavbarHeaderRight}
              />
              {finishedLoadingRegion ? (
                <Container className={containerClass}>
                  <ReportsView
                    reports={reports}
                    fetchReports={fetchReports}
                    fetchWarnings={fetchWarnings}
                    regionService={regionService}
                    region={region}
                    setRegion={setRegion}
                    timespan={timespan}
                    setTimespan={setTimespan}
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    playbackTime={playbackTime}
                    setPlaybackTime={setPlaybackTime}
                    setUserLocation={setUserLocation}
                    t={t}
                    locale={locale}
                    reportPresentation={reportPresentation}
                    showHistory={showHistory}
                    showMovie={showMovie}
                    setShowMovie={setShowMovie}
                    showList={showList}
                    onReportSelected={this.onReportSelected}
                    warningStatusVisible={warningStatusVisible}
                    onWarningLayerToggle={this.onWarningLayerToggle}
                  />
                </Container>
              ) : (
                <LoadingView />
              )
              }
            </div>
          )
        }}
      </Context.Consumer>
    )
  }

  componentDidMount() {
    this.setShowListFromQueryString()
  }

  renderNavbarHeaderLeft() {
    return <Fragment>
      <div className="relative flex md:justify-end">
        <div className='md:absolute top-8 -left-12'>
        </div>
      </div>
    </Fragment>
  }

  renderNavbarHeaderRight() {
    const { state, t } = this.context
    const { showList, showHistory, timespan } = state

    return (
      <Fragment>
        {/* <button className={showHistory ? 'aw-navbar-item--inverted' : ''} onClick={this.onClickHistory} title={t('NAVBAR.TOOLTIP.TOGGLE_TIMESPAN')}>
          <img data-src={clockIcon} className="iconic w-8 h-8" alt="history" />
          history
        </button> */}

        <TimeSwitcher
          onClick={this.onClickHistory}
          active={showHistory}
          title={t('NAVBAR.TOOLTIP.TOGGLE_TIMESPAN')}
          timespan1Label={t('NAVBAR.TOGGLE_TIME_SHORT')}
          timespan2Label={t('NAVBAR.TOGGLE_TIME_LONG')}
        />

        <ViewSwitcher onClick={this.onClickSwitch} active={showList ? 'list' : 'map'} title={t('NAVBAR.SWITCHER')} mapLabel={t('NAVBAR.TOGGLE_MAP')} listLabel={t('NAVBAR.TOGGLE_LIST')} />

        {/* <button onClick={this.onClickPlus} title={t('NAVBAR.TOOLTIP.NEW_REPORT')} className="my-8 md:my-0 btn-icon-label bg-petrol text-white active:bg-petrol-darker w-full"> */}
        <button onClick={this.onClickPlus} title={t('NAVBAR.TOOLTIP.NEW_REPORT')} className="my-0 btn-icon-label bg-petrol text-white active:bg-petrol-darker w-full">
          {/* <PlusIcon className="fill-current text-white" /><span className="md:hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span> */}
          <PlusIcon className="fill-current text-white" /><span className="hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span>
        </button>

      </Fragment>
    )
  }

  onClickSwitch(button) {
    // TODO  - replace to links, separate map and list views
    const { state, setShowList } = this.context
    const { showList } = state

    const newShowList = !showList

    const query = qs.parse(this.props.location.search)
    query.presentation = newShowList ? 'list' : 'map'

    setShowList(newShowList)

    const queryString = qs.stringify(query)
    this.props.history.replace({
      pathname: '/reports',
      search: queryString
    })
  }

  onClickHistory() {
    const { state, fetchReports, setShowHistory, setFromDate, setToDate } = this.context
    const { showHistory } = state

    const newShowHistory = !showHistory
    setShowHistory(newShowHistory)

    const { timespan } = state


    if (!isOnline()) {
      // TODO display message: offline
      return
    }

    const fetchOptions = { showHistory: newShowHistory }

    if (newShowHistory) {
      const toDate = new Date()
      const timespanInSeconds = timespanStringToSeconds(timespan)
      const fromDate = subSeconds(toDate, timespanInSeconds)
      setFromDate(fromDate)
      setToDate(toDate)
      fetchOptions.fromDate = fromDate
      fetchOptions.toDate = toDate
    }

    fetchReports(fetchOptions)
  }

  onClickPlus() {
    this.navigateToChooseEventType()
  }

  onClickCog() {
    this.navigateToSettings()
  }

  onWarningLayerToggle() {
    const { state, setWarningStatusVisible } = this.context
    const { warningStatusVisible } = state
    setWarningStatusVisible(!warningStatusVisible)
  }

  onReportSelected(report) {
    this.navigateToReportDetails(report)
  }

  navigateToReportDetails(report) {
    const { history } = this.props
    history.push(`/reports/${report.uid}`)
  }

  navigateToChooseEventType() {
    const { history } = this.props
    history.push(`/reports/new/choose-parameters/`)
  }

  setShowListFromQueryString() {
    const query = qs.parse(this.props.location.search)
    if (!query.presentation) return

    const { presentation } = query

    const { state, setShowList } = this.context
    const { showList } = state

    const newShowList = presentation === 'list'
    if (newShowList !== showList) {
      setShowList(newShowList)
    }
  }
}

ReportsScreen.contextType = Context

export default ReportsScreen
