import React, { Component } from 'react'
import Context from '../models/Context'
import Container from '../views/Container'

class GDPRScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      buttonEnabled: false
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onSwitchChange = this.onSwitchChange.bind(this)
  }

  render() {
    return (
      <Context.Consumer>
        {(context) => {
          const { t } = context
          const { buttonEnabled } = this.state
          return (
            <div className="gdpr-screen">
              <Container>
                <div className="container mx-auto px-4 pt-8 pb-12">
                  <h1 className="text-xl sm:text-3xl text-gray font-bold my-4">{ t('SCREENS.GDPR.TITLE') }</h1>
                  <p className="my-4">{ t('SCREENS.GDPR.ABSTRACT') }</p>

                  <form onSubmit={this.onSubmit}>
                    <div className="field my-2">
                      <input type="checkbox" className="mr-2" id="terms" ref={(ref) => {this.acceptTermsSwitch = ref}} onChange={this.onSwitchChange} />
                      <label htmlFor="terms" dangerouslySetInnerHTML={{__html: t('SCREENS.GDPR.TERMS_ACCEPT_TEXT')}} />
                    </div>

                    <div className="field">
                      <input type="checkbox" className="mr-2" id="privacy" ref={(ref) => {this.acceptPrivacySwitch = ref}} onChange={this.onSwitchChange} />
                      <label htmlFor="privacy" dangerouslySetInnerHTML={{__html: t('SCREENS.GDPR.GDPR_ACCEPT_TEXT')}} />
                    </div>

                    <div className="mt-4">
                      <button className="btn-primary" disabled={!buttonEnabled}>{t('SCREENS.GDPR.CONTINUE')}</button>
                    </div>
                  </form>
                </div>
              </Container>
            </div>
          )
        }}
      </Context.Consumer>
    )
  }

  goToRequestedRoute () {
    const { location, history } = this.props
    const { state } = location
    const { from = { pathname: '/'} } = state
    const { pathname } = from
    history.replace(pathname)
  }

  async onSubmit () {
    const { history } = this.props
    const { appService } = this.context

    try {
      await appService.setHasAcceptedGDPR(true)
    } catch (e) {}
    finally {
      this.goToRequestedRoute()
    }
  }

  onSwitchChange () {
    const buttonEnabled = this.acceptTermsSwitch.checked && this.acceptPrivacySwitch.checked
    this.setState({ buttonEnabled })
  }
}

GDPRScreen.contextType = Context

export default GDPRScreen
