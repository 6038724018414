import React, { Component, Fragment } from 'react'
import Navbar from '../components/Navbar'

import chevronIcon from '../assets/iconic/chevron.svg'
import Container from '../views/Container'
import Context from '../models/Context'
import { ReactComponent as CrossIcon } from "../assets/cross.svg"

class SignupSuccessScreen extends Component {
  constructor (props) {
    super(props)

    this.renderNavbarHeaderLeft = this.renderNavbarHeaderLeft.bind(this)
    this.renderNavbarHeaderRight = this.renderNavbarHeaderRight.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  render () {
    return (
        <Context.Consumer>
        {(context) => {
          const { t } = context
          const title = t('SCREENS.SIGNUP_SUCCESS.TITLE')
          return (
            <div className="signup-success-screen">
                <div>
                    <Navbar
                    title={title}
                    className="app__navbar"
                    renderHeaderLeft={this.renderNavbarHeaderLeft}
                    renderHeaderRight={this.renderNavbarHeaderRight}
                    />
                </div>
              <Container>
                <div className="container mx-auto px-4 pt-20 md:pt-32">
                  <p>{ t('SCREENS.SIGNUP_SUCCESS.TEXT') }</p>
                </div>
              </Container>
            </div>
          )
        }}
      </Context.Consumer>
    )
  }


  onBack () {
    const { history } = this.props
    history.push('/reports/')
  }

  renderNavbarHeaderLeft () {
    return (
      <Fragment>

          <button onClick={this.onBack}>
            <img data-src={chevronIcon} className="iconic w-8 h-8" data-direction="left" alt="back" />
          </button>

      </Fragment>
    )
  }

  renderNavbarHeaderRight() {
    const { t } = this.context

    return (
      <Fragment>
        {/* <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-8 md:my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full"> */}
        <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full">
          {/* <CrossIcon className="fill-current text-white" /><span className="md:hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span> */}
          <CrossIcon className="fill-current text-white" /><span className="hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span>
        </button>
      </Fragment>
    )
  }
}

SignupSuccessScreen.contextType = Context

export default SignupSuccessScreen
