import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withTimeout, TimeoutError, isOnline } from '../lib/utils/network'
import config from '../config/config'
import { errorMessagesFromApiErrors } from '../lib/utils/string'
import envelopeIcon from '../assets/iconic/envelope.svg'
import personIcon from '../assets/iconic/person.svg'
import lockIcon from '../assets/iconic/lock.svg'
import eyeIcon from '../assets/iconic/eye.svg'

class LoginView extends Component {
  constructor (props) {
    super(props)

    this.user = {
      username: null,
      password: null
    }

    this.state = {
      loginBtnEnabled: false,
      errorMessage: '',
      errorMessageLogin: '',
      errorMessagePassword: '',
      logginIn: false,
      showPassword: false
    }

    this.onLogin = this.onLogin.bind(this)
    this.toggleShowPassword = this.toggleShowPassword.bind(this)
    this.onUsernameChange = this.onUsernameChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
  }

  onTextChange = (key, text) => {
    this.user[key] = text
    if (this.state.errorMessage.length > 0) {
      this.resetErrors()
    }
    if (this.user.username && this.user.password && !this.state.loginBtnEnabled) {
      this.setState({ loginBtnEnabled: true })
    }
    if ((!this.user.username || !this.user.password) && this.state.loginBtnEnabled) {
      this.setState({ loginBtnEnabled: false })
    }
  }

  onUsernameChange () {
    this.onTextChange('username', this.usernameInput.value)
  }

  onPasswordChange () {
    this.onTextChange('password', this.passwordInput.value)
  }

  async onLogin (e) {
    e.preventDefault()

    const { t } = this.props

    // clear error message
    this.resetErrors()

    if (!isOnline()) {
      this.setState({
        errorMessage: this.props.t('HELPERS.LOGIN_NETWORK_ERROR')
      })

      return
    }

    const { timeouts } = config

    try {
      this.setState({
        logginIn: true,
        loginBtnEnabled: false
      })

      const result = await withTimeout(
        timeouts.default,
        new TimeoutError('timeout while loggin in'),
        this.props.authService.login(this.user.username, this.user.password)
      )
      if (result && result.access_token) {
        this.props.setUser(this.user)
        this.props.onLogin()
      } else {
        const errorMessages = errorMessagesFromApiErrors(result.errors, 'errorMessage')
        this.setState(errorMessages)
      }
    } catch (e) {
      let message

      if (e instanceof TimeoutError) {
        message = t('HELPERS.NETWORK_ERROR')
      }

      this.setState({
        errorMessage: message || t('HELPERS.UNEXPECTED_ERROR')
      })
    } finally {
      this.setState({
        logginIn: false,
        loginBtnEnabled: true
      })
    }
  }

  render () {
    const { idRoot } = config
    const { t } = this.props
    const { showPassword, loginBtnEnabled, loggingIn } = this.state
    const passwordInputType = showPassword ? 'text' : 'password'
    const eyeOpenClass = showPassword ? 'hidden' : ''
    const eyeClosedClass = showPassword ? '' : 'hidden'
    const submitButtonClass = loggingIn ? 'is-loading' : ''
    return (
      <div className="login-view">
        <div className="container mx-auto px-4 pt-28 md:pt-32 pb-12">
          <form onSubmit={this.onLogin} className="flex flex-col space-y-8">
            <div className="">
              <label className="label">{t('HELPERS.USERNAME')}</label>
              <div className="flex w-full md:w-1/2 input">
                <span className="input-icon">
                  <img data-src={personIcon} className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.USERNAME')} aria-hidden="true" />
                </span>
                <input className="flex-auto outline-none pl-4" type="text" ref={(ref) => this.usernameInput = ref} onChange={this.onUsernameChange} />
              </div>
              { this.state.errorMessageLogin.length > 0 && <p className="text-red mt-4">{this.state.errorMessageLogin}</p> }
            </div>

            <div>
              <label className="label">{t('HELPERS.PASSWORD')}</label>
              <div className="flex w-full md:w-1/2 input">
                <span className="input-icon">
                  <img data-src={lockIcon} className="iconic iconic-sm iconic--dampened" alt="Kommentar" />
                </span>
                <input className="flex-auto outline-none px-4" type={passwordInputType} ref={(ref) => this.passwordInput = ref} onChange={this.onPasswordChange} />
                <span className="" style={{pointerEvents: 'initial'}}>
                  <button type="button" className="input-icon mt-1.5" onClick={this.toggleShowPassword}>
                    <span className={eyeOpenClass}><img data-src={eyeIcon} data-state="open" className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.SHOW')} aria-hidden="true" /></span>
                    <span className={eyeClosedClass}><img data-src={eyeIcon} data-state="closed" className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.HIDE')} aria-hidden="true" /></span>
                  </button>
                </span>
              </div>
              { this.state.errorMessagePassword.length > 0 && <p className="text-red mt-4">{this.state.errorMessagePassword}</p> }
            </div>

            { this.state.errorMessage.length > 0 && <p className="text-red mt-4">{this.state.errorMessage}</p> }

            <div className="field">
              <div className="control">
                <button type="submit" className={`btn-primary ${submitButtonClass}`} disabled={!loginBtnEnabled}>{t('SCREENS.LOGIN.LOGIN_BUTTON')}</button>
              </div>
            </div>

            <p>{t('SCREENS.LOGIN.SIGNUP_CTA')} <Link className="link" to="/register">{t('SCREENS.SIGNUP.TITLE')}</Link>.</p>

            <p>{t('SCREENS.LOGIN.RECOVER_PASSWORD.DESCRIPTION')} <a href={`${idRoot}/user/recovery/request`} target="_blank" className="link" rel="noreferrer">{t('SCREENS.LOGIN.RECOVER_PASSWORD.RECOVER')}</a></p>

            <p>{t('SCREENS.LOGIN.RESEND_VERIFICATION.DESCRIPTION')} <a href={`${idRoot}/user/registration/resend`} target="_blank" className="link" rel="noreferrer">{t('SCREENS.LOGIN.RESEND_VERIFICATION.SEND')}</a></p>
          </form>
        </div>
      </div>
    )
  }

  componentDidMount () {
    window.IconicJS().inject('.login-view img.iconic')
  }

  resetErrors () {
    this.setState({
      errorMesage: '',
      errorMessageLogin: '',
      errorMessagePassword: ''
    })
  }

  toggleShowPassword () {
    const { showPassword } = this.state
    this.setState({ showPassword: !showPassword })
  }
}

export default LoginView
