import React, { Component, Fragment } from 'react'
import Navbar from '../components/Navbar'

import chevronIcon from '../assets/iconic/chevron.svg'
import Container from '../views/Container'
import LoginView from '../views/LoginView'
import Context from '../models/Context'
import { ReactComponent as CrossIcon } from "../assets/cross.svg"

class LoginScreen extends Component {
  constructor (props) {
    super(props)

    this.renderNavbarHeaderLeft = this.renderNavbarHeaderLeft.bind(this)
    this.renderNavbarHeaderRight = this.renderNavbarHeaderRight.bind(this)
    this.onLogin = this.onLogin.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  render () {
    return (
      <Context.Consumer>
        {(context) => {
          const {
            authService,
            setUser,
            t
          } = context
          const title = t('SCREENS.LOGIN.TITLE')
          return (
            <div>
                <Navbar
                    title={title}
                    className="app__navbar"
                    renderHeaderLeft={this.renderNavbarHeaderLeft}
                    renderHeaderRight={this.renderNavbarHeaderRight}
                />
                <Container>
                    <LoginView
                      authService={authService}
                      setUser={setUser}
                      t={t}
                      onLogin={this.onLogin}
                      onSignupCTA={this.onSignup}
                    />
                </Container>
            </div>
          )
        }}
      </Context.Consumer>
    )
  }

  onLogin () {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const redirect = params.get('redirect') || '/reports/'
    const { history } = this.props
    history.push(redirect)
  }

  onBack () {
    const { history } = this.props
    history.push('/reports/')
  }

  renderNavbarHeaderLeft () {
    return (
      <Fragment>

          <button onClick={this.onBack}>
            <img data-src={chevronIcon} className="iconic w-8 h-8" data-direction="left" alt="back" />
          </button>

      </Fragment>
    )
  }

  renderNavbarHeaderRight() {
    const { t } = this.context

    return (
      <Fragment>
        {/* <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-8 md:my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full"> */}
        <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full">
          {/* <CrossIcon className="fill-current text-white" /><span className="md:hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span> */}
          <CrossIcon className="fill-current text-white" /><span className="hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span>
        </button>
      </Fragment>
    )
  }
}

LoginScreen.contextType = Context

export default LoginScreen
