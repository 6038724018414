import React, { Component } from 'react'
import ParameterSelectionView from './ParameterSelectionView'
import ParameterSelectedView from './ParameterSelectedView'
import SubmitReportView from '../views/SubmitReportView'

class NewReportView extends Component {
  constructor (props) {
    super(props)

    const { reportingParameters, selectedParameters } = this.props
    const parametersToSelect = this.findParametersToSelect(selectedParameters, reportingParameters)
    const needsParameterSelection = parametersToSelect.length > 0

    this.state = {
      needsParameterSelection,
      parametersToSelect
    }

    this.onParameterSelected = this.onParameterSelected.bind(this)
    this.onClickSelectedParameter = this.onClickSelectedParameter.bind(this)
    this.selectionView = React.createRef()
  }

  render () {
    const { t, selectedParameters, setSelectedParameters,
      reportPresentation, newReport, setNewReport,
      createReport, createReportImage,
      userLocation, setUserLocation,
      fetchReports, savedReportsService, formatDate4Api } = this.props
    const { needsParameterSelection, parametersToSelect } = this.state
    const nextSelectionStep = selectedParameters.length + 1
    const hasSelectedParameters = selectedParameters.length > 0
    return (
        <div className="">
          <h1 className='text-xl sm:text-3xl font-bold'>{ t('SCREENS.NEW_REPORT.TITLE') }</h1>
          <p className='py-4'>{ t('SCREENS.NEW_REPORT.SUB_TITLE') }</p>

          {
            selectedParameters.map((param, index) => {
              const isFirst = index === 0
              const isLast =  index === selectedParameters.length
              const key = `parameter-${index}`
              const params = selectedParameters.slice(0, index + 1)
              return (
                <div key={key}>
                  <ParameterSelectedView
                    selectedParameters={params}
                    reportPresentation={reportPresentation}
                    step={index+1}
                    showLineTop={!isFirst}
                    showLineBottom={!isLast}
                    onClick={ () => this.onClickSelectedParameter(index) }
                  />
                </div>
              )
            })
          }

          <div ref={this.selectionView}>
          {
            needsParameterSelection ? (

                <ParameterSelectionView
                  title={null}
                  subtitle={null}
                  step={nextSelectionStep}
                  parameters={parametersToSelect}
                  onParameterSelected={this.onParameterSelected}
                  showLineTop={hasSelectedParameters}
                  showLineBottom={false}
                />
            ) : (
              <SubmitReportView
                  title={t('SCREENS.SUBMIT_REPORT.TITLE')}
                  subtitle={t('SCREENS.SUBMIT_REPORT.SUBTITLE')}
                  step={nextSelectionStep}
                  newReport={newReport}
                  reportPresentation={reportPresentation}
                  setNewReport={setNewReport}
                  setSelectedParameters={setSelectedParameters}
                  createReport={createReport}
                  createReportImage={createReportImage}
                  userLocation={userLocation}
                  setUserLocation={setUserLocation}
                  t={t}
                  fetchReports={fetchReports}
                  savedReportsService={savedReportsService}
                  formatDate4Api={formatDate4Api}
                  onSubmit={this.props.onSubmit ? this.props.onSubmit : () => {}}
                  onSave={this.props.onSave ? this.props.onSave : () => {}}
                  onInvalidReport={this.props.onInvalidReport ? this.props.onInvalidReport : () => {}}
                />
            )
          }
          </div>
        </div>
    )
  }

  findParametersToSelect (selectedParameters, reportingParameters) {
    let parametersToSelect = reportingParameters

    const remaining = [...selectedParameters]

    while (remaining.length > 0) {
      const current = remaining.shift()

      let param = parametersToSelect.find(({ id }) => id === current.id)

      if (!param) { return parametersToSelect }

      const children = param.children
      if (!children) { return [] }

      parametersToSelect = children
    }

    return parametersToSelect
  }

  onParameterSelected(item) {
    const { id, apiParameterName: name, apiParameterValue: value, title, subtitle, icon } = item
    const { newReport, setNewReport, selectedParameters, setSelectedParameters } = this.props

    if (name && value) {
      const updatedReport = Object.assign({}, newReport)
      updatedReport[name] = value
      setNewReport(updatedReport)
    }

    const selection = { id, name, value }
    selection.presentation = { title, subtitle, icon }
    const newSelectedParameters = [
      ...selectedParameters,
      selection
    ]
    setSelectedParameters(newSelectedParameters)

    const { reportingParameters } = this.props
    const parametersToSelect = this.findParametersToSelect(newSelectedParameters, reportingParameters)
    const needsParameterSelection = parametersToSelect.length > 0
    this.setState({
      parametersToSelect,
      needsParameterSelection
    })

    if (parametersToSelect.length === 0 && this.props.onParameterSelected) {
      this.props.onParameterSelected(false)
    } else {
      if (this.selectionView && this.selectionView.current) {
        this.selectionView.current.scrollIntoView()
      }
    }
  }

  onClickSelectedParameter(index) {
    const { selectedParameters, setSelectedParameters, reportingParameters } = this.props
    const newSelectedParameters = [...selectedParameters].slice(0, index)

    const parametersToSelect = this.findParametersToSelect(newSelectedParameters, reportingParameters)
    const needsParameterSelection = parametersToSelect.length > 0
    this.setState({
      parametersToSelect,
      needsParameterSelection
    })

    setSelectedParameters(newSelectedParameters)
  }
}

export default NewReportView
