import { format } from 'date-fns'
import assert from 'browser-assert'

class ReportService {
  // Constructs a report service
  // The root url of the api (for example https://api.awob.simplebytes.at/v1)
  // without the trailing slash is a required parameter
  constructor (reportsApiRoot, authService) {
    assert(reportsApiRoot, 'reportsApiRoot is required')
    this.reportsApiRoot = reportsApiRoot
    this.authService = authService
  }

  // creates a new report
  // returns a promise that resolves to the created report
  async create (report) {
    assert(report, 'report is required')

    const token = await this.authService.getToken()

    const headers = ReportService.headers(token)
    const url = this.absoluteUrl('reports')
    const body = JSON.stringify(report)
    const response = await fetch(url, {
      headers,
      body,
      method: 'POST'
    })

    return response.json()
  }

  async createImage (uid, imageUploadToken, image) {
    assert(uid, 'uid is required')
    assert(imageUploadToken, 'imageUploadToken is required')
    assert(image, 'image is required')

    const token = await this.authService.getToken()

    const url = this.absoluteUrl(`reports/${uid}/image`)

    // body as json
    const headers = ReportService.headers(token)
    const body = JSON.stringify({
      imageFile: image
    })

    const response = await fetch(url, {
      headers,
      body,
      method: 'POST'
    })
    return response.json()
  }

  // queries reports in a certain time range and rectangular geographical area
  // fromDate and toDate are expected to be Date objects,
  // and the latitude and longitude values are expected to be numbers (not strings)
  // returns a promise that resolves to a result containing the matching reports
  async query (fromDate, toDate, latMin, lngMin, latMax, lngMax) {
    assert(fromDate instanceof Date, 'fromDate is required and must be a Date')
    assert(toDate instanceof Date, 'toDate is required and must be a Date')
    assert(typeof latMin === 'number', 'latMin is required and must be a number')
    assert(typeof latMax === 'number', 'latMax is required and must be a number')
    assert(typeof lngMin === 'number', 'lngMin is required and must be a number')
    assert(typeof lngMax === 'number', 'lngMax is required and must be a number')

    const fromDateFormatted = this.formatDate4Api(fromDate)
    const toDateFormatted = this.formatDate4Api(toDate)

    const token = await this.authService.getClientToken()

    const headers = ReportService.headers(token)
    const queryParams = {
      sd: fromDateFormatted,
      ed: toDateFormatted,
      x0: latMin,
      y0: lngMin,
      x1: latMax,
      y1: lngMax
    }
    const queryString = Object.keys(queryParams)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(queryParams[k]))
      .join('&')
    const resourceUrl = this.absoluteUrl('reports')
    const url = `${resourceUrl}?${queryString}`
    const response = await fetch(url, {
      headers
    })

    return response.json()
  }

  // gets a report by id
  async get (id) {
    assert(id, 'id is required')

    const token = await this.authService.getClientToken()

    const headers = ReportService.headers(token)
    const url = this.absoluteUrl(`reports/${id}`)
    const response = await fetch(url, {
      headers
    })

    return response.json()
  }

  formatDate4Api (date) {
    return format(date, ReportService.apiDateFormat)
  }

  // private

  static HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  static headers (authToken) {
    return {
      ...ReportService.HEADERS,
      'Authorization': `Bearer ${authToken}`
    }
  }

  // static HEADERS_MULTIPART = {
  //   'Accept': 'application/json',
  //   // 'Content-Type': 'application/json'
  //   // 'Content-Type': 'multipart/form-data'
  // }

  // static headersMultipart (authToken) {
  //   return {
  //     ...ReportService.HEADERS_MULTIPART,
  //     'Authorization': `Bearer ${authToken}`
  //   }
  // }

  static apiDateFormat = 'YYYY-MM-DD[T]HH:mm:ssZ'

  absoluteUrl (relativeUrl) {
    return `${this.reportsApiRoot}/${relativeUrl}`
  }
}

export default ReportService
