import React, { Component, createRef } from 'react'

import Map from 'ol/Map'
import View from 'ol/View'
import { Vector as VectorSource, Cluster } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Style, Icon, Circle as CircleStyle, Fill, Text as TextStyle, Stroke } from 'ol/style'
import Feature from 'ol/Feature'
import { GeoJSON } from 'ol/format'
import { defaults as defaultInteractions } from 'ol/interaction'
import { Point } from 'ol/geom'
import md5 from 'blueimp-md5'
import { getCenter } from 'ol/extent'
import { transform } from 'ol/proj'
import Geolocation from 'ol/Geolocation.js'
import { defaults as defaultControls } from 'ol/control.js'
import LocationControl from './LocationControl'
import WarningLayerControl from './WarningLayerControl'
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster'
import SelectCluster from 'ol-ext/interaction/SelectCluster'
import config from '../../config/config.js'
import {transformExtent} from 'ol/proj';
import proj4 from 'proj4'
import { reproject } from 'reproject'
// import testWarnings from '../../test-warnings.json'
import { addHours, areRangesOverlapping } from 'date-fns'


import {
  region2extent,
  extent2region
} from '../../lib/utils/regionMath'

import { zamg as zamgLayer, warningStatus as warningStatusLayer, warningStatusIcons as warningStatusIconsLayer } from './MapLayers'

function transformExt(extent) {
  return transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
}

class MapView extends Component {
  static apiProjection = 'EPSG:4326'
  static mapProjection = 'EPSG:3857'
  static POLL_WARNINGS_INTERVAL = 1000 * 60 // in ms

  constructor (props) {
    super(props)
    this.mapContainer = createRef()

    const { intitialRegion, region } = props
    this.state = {
      region: region || intitialRegion
    }

    // marker layer def.
    this.markerVectorSource = new VectorSource({
      features: []
    })

    // geolocation
    this.createPositionFeature()

    // create map layers
    this.clusterLayer = new AnimatedCluster({
      name: 'Cluster',
      source: this.createCluster(),
      animationDuration: 0,
      style: this.getStyle
    })

    this.baseLayer = zamgLayer(config.tileServer)

    // warning status
    this.warningStatusLayer = warningStatusLayer()
    this.warningStatusIconsLayer = warningStatusIconsLayer()
    this.warningsGeojson = null
    this.setWarningLayerVisible(false)

    // cluster style cache
    this.styleCache = {}

    this.onWarningLayerToggle = this.onWarningLayerToggle.bind(this)

    this.warningLayerControl = new WarningLayerControl()

    this.loadWarningsHandle = setInterval(() => {
      this.loadWarnings()
    }, MapView.POLL_WARNINGS_INTERVAL)

    this.loadWarnings()
  }

  componentWillReceiveProps ({ region }) {
    if (region !== this.props.region) {
      this.setState({ region })
    }
  }

  render () {
    const { className = '' } = this.props

    return (
      <div
        className={`absolute top-0 bottom-0 right-0 left-0 ${className}`}
        ref={this.mapContainer}
      ></div>
    )
  }

  componentDidMount () {
    this.map = this.createMap(this.mapContainer.current)
    this.geolocation = this.createGeolocation()
    window.IconicJS().inject('.mapContainer img.iconic')
  }

  componentWillUnmount() {
    clearInterval(this.loadWarningsHandle)
  }

  componentDidUpdate (prevProps, prevState) {
    this.map.updateSize()
    // TODO check: maybe this is overkill
    // currently needed to draw map in the following case:
    // switch to list, resize list, switch back to map
    // if not using updateSize map stays blank

    const { warningStatusVisible } = this.props
    this.setWarningLayerVisible(warningStatusVisible)
    // this.warningStatusLayer.setVisible(warningStatusVisible)
    // this.warningStatusIconsLayer.setVisible(warningStatusVisible)

    this.updateMarkers(this.props.children)
  }

  // callback after pan and zoom by user
  onMapIdle () {
    const extent = this.map.getView().calculateExtent(this.map.getSize())
    const region = extent2region(extent, MapView.apiProjection, MapView.mapProjection)

    if (this.props.onRegionChangeComplete) {
      this.props.onRegionChangeComplete(region)
    }
  }

  updateMarkers(children) {
    const newMarkers = {} // hash of new markers
    // for each new marker, check if it already exists
    // if yes, update event listeners
    // if no, add marker
    //
    // updating of event listeners is necessary
    // because on this level markers are identified by their
    // position in this.props.children of MapView.dom.js
    // in general, marker position will change from call to call
    //
    // makers are compared by by calculating a hash over its
    // relevant properties and then comparing the hash
    React.Children.forEach(children, (child, id) => {
      const markerProps = child.props
      const { coordinate, title, description, image } = markerProps
      const marker = { id, coordinate, title, description, image }
      const hash = this.hashMarker(marker)

      newMarkers[hash] = marker
      const oldMarker = this.markerVectorSource.getFeatureById(hash)
      if (!oldMarker) {
        const feature = this.createFeature(marker, hash)
        this.markerVectorSource.addFeature(feature)
      } else {
        oldMarker.setProperties({ markerId: marker.id })
      }
    })

    // loop through existing markers and remove
    // the ones not present in the new markers
    const allFeatures = this.markerVectorSource.getFeatures()
    allFeatures.forEach((feature) => {
      const hash = feature.get('id')
      if (!newMarkers[hash]) {
        this.markerVectorSource.removeFeature(feature)
      }
    })
  }

  createPositionFeature () {
    this.positionFeature = new Feature()
    this.positionFeature.setStyle(createPositionStyle())

    this.positionFeatureVectorSource = new VectorSource({
      features: [this.positionFeature]
    })

    this.positionFeatureVectorLayer = new VectorLayer({
      source: this.positionFeatureVectorSource
    })
  }

  createGeolocation () {
    const geolocation = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: this.view.getProjection()
    })

    geolocation.on('change:position', () => {
      const coordinates = geolocation.getPosition()
      const coordinatesApi = transform(coordinates, MapView.mapProjection, MapView.apiProjection)
      const [longitude, latitude] = coordinatesApi
      const accuracy = geolocation.getAccuracy()
      this.positionFeature.setGeometry(coordinates ? new Point(coordinates) : null)
      if (this.props.onUserLocationChange) {
        this.props.onUserLocationChange({
          // compatible with navigator.geolocation.getCurrentPosition()
          coords: { longitude, latitude, accuracy },
          timestamp: Date.now()
        })
      }
    })

    if (this.props.showsUserLocation) {
      geolocation.setTracking(true)
    }

    return geolocation
  }

  createCluster () {
    return new Cluster({
      distance: 50,
      source: this.markerVectorSource
    })
  }

  createMap (mapDiv) {
    const maxExtent = transformExt([-16.43, 32.96, 45.62, 71.84])
    const extent = region2extent(this.state.region, MapView.apiProjection, MapView.mapProjection)

    const center = getCenter(extent)

    this.view = new View({
      extent: maxExtent,
      projection: MapView.mapProjection,
      center,
      zoom: 10,
      maxZoom: 14,
      minZoom: 7
    })

    setTimeout(() => {
      this.view.fit(extent)
    }, 0)

    const layers = [this.baseLayer , this.warningStatusLayer, this.warningStatusIconsLayer, this.clusterLayer, this.positionFeatureVectorLayer]
    const customControls = []

    if (this.props.showsMyLocationButton) {
      const locationControl = new LocationControl()
      locationControl.handleLocation = () => {
        this.onLocationButtonClick()
      }
      customControls.push(locationControl)
    }

    this.warningLayerControl.handleWarningLayerToggle = () => {
      this.onWarningLayerToggle()
    }

    customControls.push(this.warningLayerControl)

    const map = new Map({
      controls: defaultControls({ zoom: this.props.zoomControlEnabled }).extend(customControls),
      interactions: defaultInteractions({
        mouseWheelZoom: this._scrollEnabled,
        shiftDragZoom: this.props.zoomEnabled,
        pinchZoom: this.props.zoomEnabled,
        altShiftDragRotate: false,
        pinchRotate: false
      }),
      layers,
      target: mapDiv,
      view: this.view
    })

    this.baseLayer.setZIndex(1)
    this.warningStatusLayer.setZIndex(2)
    this.warningStatusIconsLayer.setZIndex(3)
    this.clusterLayer.setZIndex(4)
    this.positionFeatureVectorLayer.setZIndex(5)

    map.on('singleclick', (event) => this.onMapClick(event))
    map.on('moveend', (event) => this.onMapIdle(event))

    const cluster = new SelectCluster({
      pointRadius: 25,
      animate: true,
      animationDuration: 150,
      style: (feature) => {
        if (feature.values_ && feature.values_.features) {
          const { features } = feature.values_
          const size = features.length
          if (size === 1) {
            return this.featureStyle(features[0])
          } else {
            return createBadgeStyle(size.toString(), 20, 20)
          }
        }
        return createDefaultStyle()
      },
      featureStyle: (feature) => {
        if (feature.values_.selectclusterfeature) {
          return this.featureStyle(feature.values_.features[0])
        } else if (feature.values_.selectclusterlink) {
          return new Style({
            stroke : new Stroke({ color: 'rgba(0, 0, 0, 0.1)', width: 1 })
          })
        }
        return createDefaultStyle()
      }
    })

    map.addInteraction(cluster)

    cluster.on('select', function(e){
      // An object is selected and it's not a cluster
      if (e.selected[0] && e.selected[0].get('selectclusterfeature')) {
        cluster.clear()
      }
    })

    return map
  }

  createFeature (marker, hash) {
    const { id, coordinate, title, description, image, eventListenerNames } = marker
    const { latitude, longitude } = coordinate

    const geometry = new Point(transform([longitude, latitude], MapView.apiProjection, MapView.mapProjection))

    const feature = new Feature({
      id: hash,
      markerId: id,
      title,
      description,
      eventListenerNames,
      coordinate, // original
      geometry,
      image
    })
    feature.setId(hash)
    return feature
  }

  featureStyle (feature) {
    const { image } = feature.values_

    if (!image) return createDefaultStyle(feature)

    return createStyleForFeatureWithImage(image)
  }

  clusterStyle (features) {
    const size = features.length

    const representativeFeature = this.getRepresentativeFeature(features)
    const { image } = representativeFeature.values_

    if (!image) return

    return createStyleForClusterWithImage(image, size)
  }

  // gets feature that is representative of the cluster
  // current choice: "latest" marker, i.e. the marker with the highest id
  getRepresentativeFeature (features) {
    let maxMarkerId = -1
    let representativeFeature = features[0]
    features.forEach((feature) => {
      const markerId = feature.values_.markerId
      if (markerId > maxMarkerId) {
        maxMarkerId = markerId
        representativeFeature = feature
      }
    })

    return representativeFeature
  }

  getStyle = (feature, resolution) => {
    const features = feature.get('features')

    // Feature style
    // return this.positionStyle();
    return (features.length === 1) ? this.featureStyle(features[0]) : this.clusterStyle(features)
  }

  onMapClick (e) {
    const feature = this.map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
      return feature
    })

    if (!feature || !feature.values_) { return }

    const isWarning =  feature.values_.start
    if (isWarning) {
      e.preventDefault()
      return
    }

    if (feature && feature.values_.features && feature.values_.features.length === 1) {
      // no clusters
      const id = feature.values_.features[0].values_.markerId
      const { coordinate, pixel } = e
      const latitude = coordinate[0]
      const longitude = coordinate[1]
      const x = pixel[0]
      const y = pixel[1]
      // object representing an event to be sent to MapView.dom.js
      // MapView.dom.js will then find the marker by id (index)
      // in this.props.children and call the corresponding
      // handler on Marker.dom.js
      const markerEvent = {
        id,
        eventName: 'press',
        coordinate: { latitude, longitude },
        position: { x, y }
      }
      this.onMarkerEvent(markerEvent) // callback in MapView.dom.js
    }
  }

  onMarkerEvent (event) {
    const { id, eventName } = event
    const marker = this.props.children[id]
    if (eventName === 'press' && marker && marker.props && marker.props.onPress) {
      marker.props.onPress(event)
    }
  }

  onLocationButtonClick () {
    const pos = this.geolocation.getPosition()
    this.view.animate({ center: pos })
  }

  onWarningLayerToggle () {
    if (this.props.onWarningLayerToggle) {
      this.props.onWarningLayerToggle()
    }
  }

  // calculates the hash value of a marker, used to compare two markers
  hashMarker (marker) {
    const { coordinate, title, image } = marker
    const { latitude, longitude } = coordinate
    const obj = { latitude, longitude, coordinate, title, image }
    const hashValue = md5(JSON.stringify(obj))
    return hashValue
  }

  async loadWarnings() {
    const { warningStatusVisible } = this.props

    const { fetchWarnings } = this.props
    if (fetchWarnings) {
      const response = await fetchWarnings()
      let geojson
      let filteredGeojson

      // if (Math.random() >= 0.5) {
      const useTestDataAndRealData = false
      if (!useTestDataAndRealData) {
        geojson = await response.json()
        filteredGeojson = filterCurrentWarnings(geojson)
        // console.log('real data')
      } else {
        // geojson = testWarnings
        // filteredGeojson = geojson
        // console.log('test data')
      }


      proj4.defs("EPSG:31287","+proj=lcc +lat_0=47.5 +lon_0=13.3333333333333 +lat_1=49 +lat_2=46 +x_0=400000 +y_0=400000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +units=m +no_defs +type=crs");
      proj4.defs("EPSG:3857","+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs");
      this.warningsGeojson = reproject(filteredGeojson, proj4.defs('EPSG:31287'), proj4.defs('EPSG:3857'), {})

      const warningFeatures = new GeoJSON().readFeatures(this.warningsGeojson)
      this.warningLayerControl.setVisible(warningFeatures.length > 0)
      this.setWarningLayerVisible(warningStatusVisible)
    }
  }

  setWarningLayerVisible(visible) {
    if (visible && this.warningsGeojson) {
      const features = new GeoJSON().readFeatures(this.warningsGeojson)
      const featuresCloned = new GeoJSON().readFeatures(this.warningsGeojson)
      const source = this.warningStatusLayer.getSource()
      source.clear()
      source.addFeatures(features)
      source.changed()

      const featuresIcons = featuresCloned.map(f => {
        const geometry = f.getGeometry()
        const point = geometry.getInteriorPoints().getPoint(0)
        f.setGeometry(point)
        return f
      })
      const sourceIcons = this.warningStatusIconsLayer.getSource()
      sourceIcons.clear()
      sourceIcons.addFeatures(featuresIcons)
      sourceIcons.changed()
    } else {
      const source = this.warningStatusLayer.getSource()
      source.clear()
      source.changed()
      const sourceIcons = this.warningStatusIconsLayer.getSource()
      sourceIcons.clear()
      sourceIcons.changed()
    }


   this.warningStatusLayer.setVisible(visible)
   this.warningStatusIconsLayer.setVisible(visible)
   this.clusterLayer.setVisible(true)
   this.positionFeatureVectorLayer.setVisible(true)
  }
}

function filterCurrentWarnings(warnings) {
  const { type, features } = warnings
  const filterFromDate = new Date()
  const filterToDate = addHours(filterFromDate, 3)
  const filteredFeatures = features.filter(w => {
    const { properties } = w
    const { start, end } = properties
    const startDate = dateFromTimestamp(start)
    const endDate = dateFromTimestamp(end)
    return areRangesOverlapping(startDate, endDate, filterFromDate, filterToDate)
  })
  return { type, features: filteredFeatures }
}

function createStyleForFeatureWithImage (image) {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: image,
        scale: scale()
      })
    })
}

function createStyleForClusterWithImage (image, clusterSize) {
  return [createImageStyle(image), createBadgeStyle(clusterSize.toString(), 20, 20)]
}

function createImageStyle (image) {
  return new Style({
    image: new Icon({
      anchor: [0.25, 0.1],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: image,
      scale: scale()
    })
  })
}

function createBadgeStyle (text, offsetX, offsetY) {
  return new Style({
    image: new CircleStyle({
      radius: 12,
      fill: new Fill({
        color: '#3399CC'
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2
      })
    }),
    offsetX,
    offsetY,
    text: new TextStyle({
        text,
        font: '12px sans-serif',
        fill: new Fill(
          {
            color: '#fff'
          })
    })
  })
}

function createPositionStyle () {
  return new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC'
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2
      })
    })
  })
}

function createDefaultStyle () {
  return createPositionStyle()
}

function scale () {
  const ratio = 0.6896551724
  return ratio
  // return window.devicePixelRatio >= 2 ? ratio : ratio
}

function dateFromTimestamp(ts) {
  return new Date(ts * 1000)
}

export default MapView
