function withTimeout (timeout, err, promise) {
  const p = new Promise(function (resolve, reject) {
    promise.then(resolve, reject)
    setTimeout(reject.bind(null, err), timeout)
  })

  p.catch((e) => { })
  return p
}

function isOnline () {
  return navigator.onLine
}

class TimeoutError extends Error {
}

export {
  withTimeout,
  TimeoutError,
  isOnline
}
