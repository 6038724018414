import React, { Component, Fragment } from 'react'
import Navbar from '../components/Navbar'

import Container from '../views/Container'
import NewReportView from '../views/NewReportView'
import Context from '../models/Context'
import { ReactComponent as CrossIcon } from "../assets/cross.svg"

class NewReportScreen extends Component {
  constructor(props) {
    super(props)

    this.navigateToReports = this.navigateToReports.bind(this)
    this.renderNavbarHeaderRight = this.renderNavbarHeaderRight.bind(this)
    this.onBack = this.onBack.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onInvalidReport = this.onInvalidReport.bind(this)
  }

  static contextType = Context
  render () {
    return (
      <Context.Consumer>
        {(context) => {
          const { state, setSelectedParameters, setNewReport, reportPresentation, t,
            createReport, createReportImage, setUserLocation,
            fetchReports, savedReportsService, formatDate4Api } = context
          const { reportingParameters, selectedParameters, newReport, userLocation } = state

          return (
            <div className="">
             <Navbar
                title={t('SCREENS.SUBMIT_REPORT.TITLE')}
                className="app__navbar"
                renderHeaderRight={this.renderNavbarHeaderRight}
              />
              <Container>
              <div className="container mx-auto px-4 pt-32 pb-12">

                <NewReportView
                  t={t}
                  reportingParameters={reportingParameters}
                  selectedParameters={selectedParameters}
                  setSelectedParameters={setSelectedParameters}
                  newReport={newReport}
                  setNewReport={setNewReport}
                  reportPresentation={reportPresentation}
                  createReport={createReport}
                  createReportImage={createReportImage}
                  userLocation={userLocation}
                  setUserLocation={setUserLocation}
                  fetchReports={fetchReports}
                  savedReportsService={savedReportsService}
                  formatDate4Api={formatDate4Api}
                  onSubmit={this.onSubmit}
                  onSave={this.onSave}
                  onInvalidReport={this.onInvalidReport}
                />
                </div>
              </Container>
            </div>
          )
        }}
      </Context.Consumer>
    )
  }

  onSubmit (report) {
    const { latitude, longitude } = report

    const { state, setRegion, fetchReports, setNewReport, setSelectedParameters } = this.context
    const { region } = state
    const { latitudeDelta, longitudeDelta } = region
    const newRegion = {
      latitude, longitude, latitudeDelta, longitudeDelta
    }

    setRegion(newRegion)
    setSelectedParameters([])
    setNewReport({})

    fetchReports({ region: newRegion })

    this.navigateToReports()
  }

  onSave () {
    this.navigateToReports()
  }

  navigateToReports () {
    // TODO reset router?
    const { history } = this.props
    history.replace('/reports')
  }

  onInvalidReport () {
    this.navigateToReports()
  }

  renderNavbarHeaderRight() {
    const { t } = this.context

    return (
      <Fragment>
        {/* <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-8 md:my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full"> */}
        <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full">
          {/* <CrossIcon className="fill-current text-white" /><span className="md:hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span> */}
          <CrossIcon className="fill-current text-white" /><span className="hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span>
        </button>
      </Fragment>
    )
  }

  onBack () {
    const { history } = this.props
    history.push('/reports/')
  }
}

NewReportScreen.contextType = Context

export default NewReportScreen
