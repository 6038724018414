function ucfirst (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Builds error messages from api errors
 *
 * @param {array} errors api errors
 * @param {string} objectPropertyPrefix this will be prepended to the field name when setting properties on the returned object
 *
 * @returns an object of error messages, for example
 * { errorMessageEmail: 'This email has already been taken' }
 */
function errorMessagesFromApiErrors (errors, objectPropertyPrefix) {
  const errorObject = {}

  Object.keys(errors).forEach((field) => {
    const property = `${objectPropertyPrefix}${ucfirst(field)}`

    errors[field].forEach((message) => {
      // const languageKey = `${languageKeyPrefix}.${field.toUpperCase()}.${keyword.toUpperCase()}`
      // const message = t(languageKey)
      errorObject[property] = message
    })
  })

  return errorObject
}

export {
  ucfirst,
  errorMessagesFromApiErrors
}
