import { toLonLat, transformExtent } from 'ol/proj'
import { boundingExtent } from 'ol/extent'
import config from '../../config/config'

const apiRegionToCenterRegion = (region) => {
  const latDelta = (region.latMax - region.latMin) / 2
  const longDelta = (region.longMax - region.longMin) / 2

  return {
    latitude: region.latMin + latDelta,
    longitude: region.longMin + latDelta,
    latitudeDelta: latDelta / 2,
    longitudeDelta: longDelta / 2
  }
}

const centerRegionToApiRegion = (region) => {
  return {
    latMin: region.latitude - region.latitudeDelta / 2,
    lngMin: region.longitude - region.longitudeDelta / 2,
    latMax: region.latitude + region.latitudeDelta / 2,
    lngMax: region.longitude + region.longitudeDelta / 2
  }
}

// converts regions (react-native-maps) to open layers extents
const region2extent = (region, apiProjection, mapProjection) => {
  const { latitude, longitude, latitudeDelta, longitudeDelta } = region
  const halfLatSpan = latitudeDelta / 2
  const halfLngSpan = longitudeDelta / 2

  const south = latitude - halfLatSpan
  const north = latitude + halfLatSpan
  const west = longitude - halfLngSpan
  const east = longitude + halfLngSpan

  const extent = boundingExtent([[west, south], [east, north]])
  const extentOl = transformExtent(
    extent,
    apiProjection,
    mapProjection
  )
  return extentOl
}

// converts open layers extent to regions (react-native-maps)
const extent2region = (extentOl, apiProjection, mapProjection) => {
  const extent = transformExtent(
    extentOl,
    mapProjection,
    apiProjection
  )

  const sw = [extent[0], extent[1]]
  const ne = [extent[2], extent[3]]

  // const swLonLat = toLonLat(sw, config.apiProjection)
  // const neLonLat = toLonLat(ne, config.apiProjection)
  const swLonLat = toLonLat(sw, 'EPSG:4326')
  const neLonLat = toLonLat(ne, 'EPSG:4326')

  const latitudeDelta = neLonLat[1] - swLonLat[1]
  const longitudeDelta = neLonLat[0] - swLonLat[0]
  const latitude = swLonLat[1] + latitudeDelta / 2
  const longitude = swLonLat[0] + longitudeDelta / 2

  const region = { latitude, longitude, latitudeDelta, longitudeDelta }
  return region
}

// checks if two regions are the same (sufficiently close to each other)
const sameRegion = (a, b) => {
  if (!a && !b) return true
  if (!a || !b) return false
  const tolerance = 0.0001
  return Math.abs(a.latitude - b.latitude) < tolerance &&
    Math.abs(a.longitude - b.longitude) < tolerance &&
    Math.abs(a.latitudeDelta - b.latitudeDelta) < tolerance &&
    Math.abs(a.longitudeDelta - b.longitudeDelta) < tolerance
}

// get surroundings of position (when showing a single report's location on the map)
function regionSurroundingPosition (position) {
  if (!position) return config.fallbackRegion

  const { latitude, longitude } = position
  const { latitudeDelta, longitudeDelta } = config.positionSurroundings

  return {
    latitude,
    longitude,
    latitudeDelta,
    longitudeDelta
  }
}

export {
  region2extent,
  extent2region,
  sameRegion,
  apiRegionToCenterRegion,
  centerRegionToApiRegion,
  regionSurroundingPosition
}
