import { subSeconds } from 'date-fns'

/*
  class ReportRepository manages fetching reports from the api and updating via mqtt
  does not have it's own state but relies on getReports and setReports functions passed to its constructor
*/
class ReportRepository {
  static SCAN_OLD_REPORTS_INTERVAL = 10000 // in ms
  static POLL_REPORTS_INTERVAL = 10000 // in ms
  /*
    constructs a ReportRepository

    reportService is an instance of ReportService
    mqttClient is an instance of an mqtt client returned from mqtt.connect
    getReports and setReports are functions that shall be called to get and set reports
  */
  constructor (
    reportService,
    mqttClient,
    mqttOptions,
    getReports,
    setReports
  ) {
    this.reportService = reportService
    this.mqttClient = mqttClient
    this.mqttOptions = mqttOptions
    this.mqttConnected = false
    this.getReports = getReports
    this.setReports = setReports

    this.boundingBox = [0.0, 0.0, 0.0, 0.0] // latMin, lngMin, latMax, lngMax

    // bind methods to this object instance
    this.onMqttMessageArrived = this.onMqttMessageArrived.bind(this)
    this.onMqttConnectionLost = this.onMqttConnectionLost.bind(this)
    this.removeOldReports = this.removeOldReports.bind(this)
    this.pollReports = this.pollReports.bind(this)

    // needed to quickly find report by uid
    this.reportIndex = {}

    this.mqttClient.onMessageArrived = this.onMqttMessageArrived
    this.mqttClient.onConnectionLost = this.onMqttConnectionLost

    this.removeOldReportsHandle = null
    this.pollReportsHandle = null
  }

  // fetches reports within bounding box latMin, lngMin, latMax, lngMax
  // in the timespan fromDate - toDate
  async fetchReports (fromDate, toDate, latMin, lngMin, latMax, lngMax) {
    this.stopReportsSubscription()
    this.boundingBox = [latMin, lngMin, latMax, lngMax]
    const response = await this.reportService.query(fromDate, toDate, latMin, lngMin, latMax, lngMax)
    if (response.reports) {
      const { reports } = response
      this.setReports(reports)
      this.indexReports(reports)
    }
  }

  // fetches recent reports within bounding box latMin, lngMin, latMax, lngMax and from now
  // back a timespan (timespanInSeconds seconds back from now)
  async fetchRecentReports (timespan, latMin, lngMin, latMax, lngMax) {
    this.startReportsSubscription()
    this._fetchRecentReports(timespan, latMin, lngMin, latMax, lngMax)
  }

  async _fetchRecentReports (timespan, latMin, lngMin, latMax, lngMax) {
    const toDate = new Date()
    const fromDate = subSeconds(toDate, timespan)
    this.timespan = timespan
    this.boundingBox = [latMin, lngMin, latMax, lngMax]
    const response = await this.reportService.query(fromDate, toDate, latMin, lngMin, latMax, lngMax)
    if (response.reports) {
      const { reports } = response
      this.setReports(reports)
      this.indexReports(reports)
    }
  }

  // private

  // adds a new report
  addReport (report) {
    this.setReports([
      report, // newest report goes first
      ...this.getReports()
    ])
    this.indexFirstReport(this.getReports())
  }

  // updates an existing report, index from last to first element (-1: last element, -reports.length: first element)
  updateReport (report, index) {
    const i = this.getReports().length + index
    this.setReports([
      ...this.getReports().slice(0, i),
      report,
      ...this.getReports().slice(i + 1)
    ])
  }

  indexFirstReport (reports) {
    const report = reports[0]
    const { uid } = report
    this.reportIndex[uid] = -reports.length
  }

  // index reports by uid
  indexReports (reports) {
    this.reportIndex = {}
    for (let i = 0; i < reports.length; i++) {
      // indexing from last (-1) to first element (- reports.length)
      // so index can be easily updated when adding report to front of reports array
      const index = i - reports.length
      const uid = reports[i].uid
      this.reportIndex[uid] = index
    }
  }

  startReportsSubscription () {
    this.mqttConnectAndSubscribe()
    this.startPollingReports()
    this.startRemovingOldReports()
  }

  stopReportsSubscription () {
    this.mqttDisconnect()
    this.stopPollingReports()
    this.stopRemovingOldReports()
  }

  removeOldReports () {
    const toDate = new Date()
    const fromDate = subSeconds(toDate, this.timespan)
    const filteredReports = this.getReports().filter((report) => {
      const createdAt = new Date(report.createdAt)
      const isOld = createdAt < fromDate
      return !isOld
    })
    this.setReports(filteredReports)
    this.indexReports(filteredReports)
  }

  pollReports () {
    if (this.mqttConnected) return

    this._fetchRecentReports(this.timespan, ...this.boundingBox)
  }

  mqttConnectAndSubscribe () {
    const { useSSL, topic } = this.mqttOptions

    const mqttOptions = {
      useSSL,
      onSuccess: () => {
        this.mqttConnected = true
        this.mqttClient.subscribe(topic)
      },
      onFailure: () => {
      },
      reconnect: true
    }

    if (this.mqttConnected) return

    try {
      this.mqttClient.connect(mqttOptions)
    } catch (e) {
    }
  }

  mqttDisconnect () {
    if (!this.mqttConnected) return

    try {
      this.mqttClient.disconnect()
    } catch (e) {
      //
    }
  }

  reportIsWithinBoundingBox (report) {
    const { latitude: lat, longitude: lng } = report
    const [latMin, lngMin, latMax, lngMax] = this.boundingBox
    return lat >= latMin && lat <= latMax &&
      lng >= lngMin && lng <= lngMax
  }

  // mqtt callbacks

  onMqttMessageArrived (message) {
    const { payloadString } = message
    const payload = JSON.parse(payloadString)
    const { report } = payload

    if (!this.reportIsWithinBoundingBox(report)) {
      return
    }

    const uid = report.uid
    const index = this.reportIndex[uid]
    if (index !== undefined) {
      this.updateReport(report, index)
    } else {
      this.addReport(report)
    }
  }

  onMqttConnectionLost () {
    this.mqttConnected = false
  }

  startPollingReports () {
    this.stopPollingReports()
    this.pollReportsHandle = setInterval(this.pollReports, ReportRepository.POLL_REPORTS_INTERVAL)
  }

  stopPollingReports () {
    if (this.pollReportsHandle) {
      clearInterval(this.pollReportsHandle)
    }
  }

  startRemovingOldReports () {
    this.stopRemovingOldReports()
    this.removeOldReportsHandle = setInterval(this.removeOldReports, ReportRepository.SCAN_OLD_REPORTS_INTERVAL)
  }

  stopRemovingOldReports () {
    if (this.removeOldReportsHandle) {
      clearInterval(this.removeOldReportsHandle)
    }
  }
}

export default ReportRepository
