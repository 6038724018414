import React, { Component, Fragment } from 'react'
import Navbar from '../components/Navbar'

import chevronIcon from '../assets/iconic/chevron.svg'
import Container from '../views/Container'
import SubmitReportView from '../views/SubmitReportView'
import Context from '../models/Context'

class SubmitReportScreen extends Component {
  static contextType = Context

  constructor (props) {
    super(props)

    this.renderNavbarHeaderLeft = this.renderNavbarHeaderLeft.bind(this)
    this.navigateBack = this.navigateBack.bind(this)
    this.navigateToReports = this.navigateToReports.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onInvalidReport = this.onInvalidReport.bind(this)
  }

  render () {
    return (
      <Context.Consumer>
        {(context) => {
          const {
            state,
            reportPresentation,
            setNewReport, setSelectedParameters,
            createReport, createReportImage,
            setUserLocation,
            t,
            fetchReports,
            savedReportsService,
            formatDate4Api
          } = context
          const { newReport, userLocation } = state

          return (
            <div className="">
             <Navbar
                title={t('SCREENS.SUBMIT_REPORT.TITLE')}
                className="app__navbar"
                renderHeaderLeft={this.renderNavbarHeaderLeft}
              />
              <Container>
                <div class="">
                <SubmitReportView
                  newReport={newReport}
                  reportPresentation={reportPresentation}
                  setNewReport={setNewReport}
                  setSelectedParameters={setSelectedParameters}
                  createReport={createReport}
                  createReportImage={createReportImage}
                  userLocation={userLocation}
                  setUserLocation={setUserLocation}
                  t={t}
                  fetchReports={fetchReports}
                  savedReportsService={savedReportsService}
                  formatDate4Api={formatDate4Api}
                  onSubmit={this.onSubmit}
                  onSave={this.navigateToReports}
                  onInvalidReport={this.onInvalidReport}
                />
                </div>
              </Container>
            </div>
          )
        }}
      </Context.Consumer>
    )
  }

  onSubmit (report) {
    const { latitude, longitude } = report

    const { state, setRegion, fetchReports, setNewReport, setSelectedParameters } = this.context
    const { region } = state
    const { latitudeDelta, longitudeDelta } = region
    const newRegion = {
      latitude, longitude, latitudeDelta, longitudeDelta
    }

    setRegion(newRegion)
    setSelectedParameters([])
    setNewReport({})

    fetchReports({ region: newRegion })

    this.navigateToReports()
  }

   renderNavbarHeaderLeft () {
    return (
      <Fragment>
       
          <button onClick={this.navigateBack}>
            <img data-src={chevronIcon} className="iconic w-8 h-8" data-direction="left" alt="back" />
          </button>
       
      </Fragment>
    )
  }

  navigateBack () {
    const { state, setSelectedParameters, setNewReport } = this.context
    const { selectedParameters, newReport } = state
    const { history } = this.props

    const newSelectedParameters = [...selectedParameters]
    const lastSelectedParameter = newSelectedParameters.pop()
    setSelectedParameters(newSelectedParameters)

    const newNewReport = Object.assign({}, newReport)
    delete newNewReport[lastSelectedParameter.name]
    setNewReport(newNewReport)

    // const { reportBuilder } = this.context
    history.push('/reports/new/choose-parameters')
    // const hasEventSpecificParameter = reportBuilder.getRequiredEventSpecificParameterType()
    // if (hasEventSpecificParameter) {
    //   history.push('/reports/new/choose-event-specifics')
    // } else {
    //   history.push('/reports/new/choose-event-type')
    // }
  }

  navigateToReports () {
    // TODO reset router?
    const { history } = this.props
    history.replace('/reports')
  }

  onInvalidReport () {
    this.navigateToReports()
  }
}

SubmitReportScreen.contextType = Context

export default SubmitReportScreen
