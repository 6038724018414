import React, { Component } from "react";


// import './ActivityIndicator.scss'
class TimeSwitcher extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { timespan1Label, timespan2Label, title, active, onClick } = this.props;

    return (
      <div>
        {/* <p className="text-gray md:hidden">{title}</p> */}
        <p className="text-gray hidden">{title}</p>
        {/* <div className="flex my-4 md:my-0 shadow-md"> */}
        <div className="flex my-0 shadow-md">
          <button
            onClick={() => onClick()}
            title={timespan1Label}
            className={
              !active
                ? "btn-switch text-white bg-red active:bg-red-darker rounded-l-md"
                : "btn-switch text-gray-lighter bg-white active:bg-gray-lightest active:border-2 rounded-l-md"
            }
          >
            <span className="block whitespace-nowrap overflow-ellipsis">
              {timespan1Label}
            </span>
          </button>
          <button
            onClick={() => onClick()}
            title={timespan2Label}
            className={
              active
                ? "btn-switch text-white bg-red active:bg-red-darker rounded-r-md"
                : "btn-switch text-gray-lighter bg-white active:bg-gray-lightest active:border-2 rounded-r-md"
            }
          >
            <span className="block whitespace-nowrap overflow-ellipsis">
              {timespan2Label}
            </span>
          </button>
        </div>
      </div>
    );
  }
}

export default TimeSwitcher;
