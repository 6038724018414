import React, { Component, Fragment } from 'react'
import Navbar from '../components/Navbar'

import chevronIcon from '../assets/iconic/chevron.svg'
import Container from '../views/Container'
import LoadingView from '../views/LoadingView'
import ReportDetailsView from '../views/ReportDetailsView'
import Context from '../models/Context'
import { ReactComponent as CrossIcon } from "../assets/cross.svg"

class ReportDetailsScreen extends Component {
  constructor (props) {
    super(props)

    this.state = {
      report: null,
      isLoading: true
    }

    this.renderNavbarHeaderLeft = this.renderNavbarHeaderLeft.bind(this)
    this.renderNavbarHeaderRight = this.renderNavbarHeaderRight.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  render () {
    const { isLoading, report } = this.state
    return (
      <Context.Consumer>
        {(context) => {
          const { reportPresentation, reportBuilder, t } = context
          const title = t('SCREENS.REPORT_DETAILS.TITLE')
          return (
            <div>
              <Navbar
                title={title}
                className="app__navbar"
                renderHeaderLeft={this.renderNavbarHeaderLeft}
                renderHeaderRight={this.renderNavbarHeaderRight}
              />
              <Container className="container mx-auto px-4 pt-20 sm:pt-36">
                { isLoading ? (
                  <LoadingView />
                ) : (
                  <ReportDetailsView
                    report={report}
                    reportPresentation={reportPresentation}
                    reportBuilder={reportBuilder}
                    t={t}
                    onClose={() => this.props.navigation.goBack(null)}
                  />
                ) }
              </Container>
            </div>
          )
        }}
      </Context.Consumer>
    )
  }

  componentDidMount() {
    this.fetchReport()
  }

  async fetchReport () {
    const id = this.props.match.params.id
    const { fetchReport } = this.context
    const report = await fetchReport(id)
    this.setState({ report, isLoading: false })
  }

  renderNavbarHeaderLeft () {
    return (
      <Fragment>
          <button onClick={this.onBack} className={ this.state.showList ? 'aw-navbar-item--inverted' : ''}>
            <img data-src={chevronIcon} className="iconic w-8 h-8" data-direction="left" alt="back" />
          </button>
      </Fragment>
    )
  }

  renderNavbarHeaderRight() {
    const { state, t } = this.context
    const { showList, showHistory, timespan } = state

    return (
      <Fragment>
        {/* <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-8 md:my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full"> */}
        <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full">
          {/* <CrossIcon className="fill-current text-white" /><span className="md:hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span> */}
          <CrossIcon className="fill-current text-white" /><span className="hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span>
        </button>
      </Fragment>
    )
  }

  onBack () {
    const { history } = this.props
    history.push('/reports/')
  }
}

ReportDetailsScreen.contextType = Context

export default ReportDetailsScreen
