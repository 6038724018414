import baseSchema from './report.schema.json'

import closeLightningReport from './close-lightning-report.schema.json'
import floodReport from './flood-report.schema.json'
import freshSnowCoverReport from './fresh-snow-cover-report.schema.json'
import hailReport from './hail-report.schema.json'
import landslideAndAvalancheReport from './landslide-and-avalanche-report.schema.json'
import noPrecipitationReport from './no-precipitation-report.schema.json'
import otherReport from './other-report.schema.json'
import rainOrSnowReport from './rain-or-snow-report.schema.json'
import reducedVisibilityReport from './reduced-visibility-report.schema.json'
import severeWindReport from './severe-wind-report.schema.json'
import whirlWindReport from './whirl-wind-report.schema.json'

const eventSpecificSchemas = [
  closeLightningReport,
  floodReport,
  freshSnowCoverReport,
  hailReport,
  landslideAndAvalancheReport,
  noPrecipitationReport,
  otherReport,
  rainOrSnowReport,
  reducedVisibilityReport,
  severeWindReport,
  whirlWindReport
]

export { baseSchema, eventSpecificSchemas }
