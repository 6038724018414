import React, { Component } from "react";
import ListItemLayoutView from "./ListItemLayoutView";

class ParameterSelectedView extends Component {
  render() {
    const { selectedParameters, reportPresentation, step, showLineTop, showLineBottom } = this.props;

    const title =
      reportPresentation.getSubtitleForParameterSelection(selectedParameters) ||
      reportPresentation.getTitleForParameterSelection(selectedParameters);
    const description =
      reportPresentation.getDescriptionForParameterSelection(
        selectedParameters
      );
    const icon =
      reportPresentation.getImageForParameterSelection(selectedParameters);

    return (
      <ListItemLayoutView
        text={step}
        isActive={false}
        showLineTop={showLineTop}
        showLineBottom={showLineBottom}
        onClick={this.props.onClick ? this.props.onClick : () => {}}
      >
        <div
          className="cursor-pointer flex flex-wrap sm:flex-nowrap"
          onClick={this.props.onClick ? this.props.onClick : () => {}}
        >
          <img src={icon} alt={title} className="h-16 w-16" />
          <div className="pl-2 sm:pl-8 justify-center flex flex-col w-full sm:w-auto mt-2 sm:mt-0">
            <h3 className="text-lg sm:text-xl font-bold">{title}</h3>
            <p className="py-4">{description}</p>
          </div>
        </div>
      </ListItemLayoutView>
    );
  }
}

export default ParameterSelectedView;
