import Storage from 'react-native-storage'
import config from '../config/config'

class RegionService {
  constructor () {
    this.storage = new Storage({
      size: 1000,
      storageBackend: window.localStorage,
      defaultExpires: 1000 * 3600 * 24 * 30,
      enableCache: true,
      sync: {
      }
    })
  }

  save = (region) => {
    // do not save data which can cause issues later
    if (region.latitudeDelta === 0 || region.longitudeDelta === 0) return false

    this.storage.save({
      key: 'regionState',
      data: region
    })
  }

  load = async () => {
    try {
      const region = await this.storage.load({
        key: 'regionState'
      })

      return region
    } catch (err) {
      if (err.name === 'NotFoundError' || err.name === 'ExpiredError') {
        // save vienna
        // todo save current location
        this.save(config.fallbackRegion)
      } else {
      }
    }
    return config.fallbackRegion
  }
}

const regionServiceInstance = new RegionService()
export default regionServiceInstance
