import { ReactComponent as UserIcon } from "../assets/user.svg"
import { ReactComponent as ListIcon } from "../assets/list.svg"
import { ReactComponent as MapIcon } from "../assets/map.svg"
import { ReactComponent as PlusIcon } from "../assets/plus.svg"

const Buttons = () => (
  <div className="bg-gray-lightest min-h-screen py-4">
    <div className="container mx-auto px-4">
      <div className="my-4">
        <button className="btn-primary">
          Send Report
        </button>
      </div>
      <div className="my-4">
        <button className="btn-icon bg-petrol text-white active:bg-petrol-darker">
          <UserIcon />
        </button>
      </div>

      <div className="my-4">
        <button className="md:w-12 btn-icon-label bg-petrol text-white active:bg-petrol-darker w-full">
          <PlusIcon className="h-12 fill-current text-white" /><span className="md:hidden whitespace-nowrap">Add new</span>
        </button>
      </div>

      <div className="my-4 flex flex-row">
        <button className="md:w-12 btn-switch text-gray-lighter bg-white active:bg-gray-lightest border-gray-lighter active:border-2 rounded-l-md">
          <MapIcon className="h-12 fill-current text-gray" /><span className="whitespace-nowrap md:hidden">Map</span>
        </button>
        <button className="md:w-12 btn-switch text-white bg-red active:bg-red-darker rounded-r-md">
          <ListIcon className="h-12 fill-current text-white " /><span className="whitespace-nowrap md:hidden">List</span>
        </button>
      </div>
    </div>
  </div>
);

export default Buttons
