import React, { Component } from "react";

class ParameterView extends Component {
  render() {
    const { parameter } = this.props;

    const { title, icon: icons, subtitle: description } = parameter;
    const icon = icons.length > 0 ? icons[0].url : "";

    return (
      <div className="cursor-pointer border-solid border border-gray-light p-4 flex flex-wrap sm:flex-nowrap md:mx-4 my-2 md:my-4 rounded-md">
        <div className="w-full xs:w-12 h-12 mb-2 flex-0">
          <img className="w-full xs:w-12 h-full" src={icon} alt={title} />
        </div>

        <div className="pl-4 flex-1">
          <h3 className="font-bold">{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    );
  }
}

export default ParameterView;
