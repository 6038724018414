import React from 'react'
// import { Switch } from 'react-router'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import AppRoute from './AppRoute'
import GDPRScreen from './screens/GDPRScreen'
import ReportsScreen from './screens/ReportsScreen'
import ReportDetailsScreen from './screens/ReportDetailsScreen'
// import ChooseEventTypeScreen from './screens/ChooseEventTypeScreen'
import SubmitReportScreen from './screens/SubmitReportScreen'
import NewReportScreen from './screens/NewReportScreen'
import LoginScreen from './screens/LoginScreen'
import SignupScreen from './screens/SignupScreen'
import SignupSuccessScreen from './screens/SignupSuccessScreen'
import ChangePasswordScreen from './screens/ChangePasswordScreen'
import MyScreen from './screens/MyScreen'
import EditProfileScreen from './screens/EditProfileScreen'
import SettingsScreen from './screens/SettingsScreen'
import Typography from './screens/Typography'
import Buttons from './screens/Buttons'

function AppRouter() {
  return (
    <Router>
      <Switch>
        <AppRoute path="/" exact component={() => { return <Redirect to="/reports" />}} />
        <Route path="/gdpr/" component={GDPRScreen} />
        <Route path="/typography/" component={Typography} />
        <Route path="/buttons/" component={Buttons} />
        <AppRoute path="/reports/" exact component={ReportsScreen} />
        {/* <AppRoute path="/reports/new/choose-parameters/" exact component={ChooseEventTypeScreen} /> */}
        <AppRoute path="/reports/new/choose-parameters/" exact component={NewReportScreen} />
        <AppRoute path="/reports/new/submit/" exact component={SubmitReportScreen} />
        <AppRoute path="/reports/:id/" component={ReportDetailsScreen} />
        <AppRoute path="/login/" component={LoginScreen} forbidAuth />
        <AppRoute path="/register/" component={SignupScreen} forbidAuth />
        <AppRoute path="/register-success/" component={SignupSuccessScreen} forbidAuth />
        <AppRoute path="/change-password/" component={ChangePasswordScreen} requireAuth />
        <AppRoute path="/my/" component={MyScreen} requireAuth />
        <AppRoute path="/settings/" component={SettingsScreen} />
        <AppRoute path="/edit-profile/" component={EditProfileScreen} requireAuth />
        <AppRoute component={() => { return <Redirect to="/reports" />}} />
      </Switch>
    </Router>
  )
}

export default AppRouter
