import React, { Component } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as HamburgerIcon } from "../assets/hamburger.svg";
import { ReactComponent as ChevronRightIcon } from "../assets/chevron-right.svg";
import { ReactComponent as UserIcon } from "../assets/user.svg"
import Context from "../models/Context";
import Facebook from 'react-sharingbuttons/dist/buttons/Facebook'
import Twitter from 'react-sharingbuttons/dist/buttons/Twitter'
import Email from 'react-sharingbuttons/dist/buttons/Email'
import 'react-sharingbuttons/dist/main.css'
import '../components/sharing-buttons.scss'

const MOBILE_BREAKPOINT = 768;

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      isNavbarOpen: false,
      isHamburgerMenuOpen: false // window.innerWidth < MOBILE_BREAKPOINT,
    };
  }

  componentDidMount() {
    window.IconicJS().inject(".aw-navbar img.iconic");
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this), false);
  }

  handleResize = () => {
    // always show the small menu inside on mobile, hide when resize
    // this.setState({
    //   isHamburgerMenuOpen: false, // window.innerWidth < MOBILE_BREAKPOINT,
    // });
    // always show the navbar on desktop, close when it changes to mobile
    // this.setState({
    //   isNavbarOpen: true // window.innerWidth >= MOBILE_BREAKPOINT,
    // });
  };

  toggleMenu = () => {
    // console.log(this.state);

    this.setState({
      isHamburgerMenuOpen: !this.state.isHamburgerMenuOpen,
    });

    // show either the full menu (mobile) or toggle the dropdown menu (desktop)
    // if (window.innerWidth < MOBILE_BREAKPOINT) {
    //   this.setState({
    //     isNavbarOpen: !this.state.isNavbarOpen,
    //   });
    // } else {
    //   this.setState({
    //     isHamburgerMenuOpen: !this.state.isHamburgerMenuOpen,
    //   });
    // }
  };
  render() {
    const { renderHeaderLeft, renderHeaderRight } = this.props;

    return (
      <Context.Consumer>
        {(context) => {
          const { t, state } = context;
          const { user } = state;
          const navbarPosition = this.state.isNavbarOpen
            ? "right-0"
            : "-right-80";

          return (
            <div className="fixed z-10 top-0 left-0 right-0">
            <div
            // className={`fixed flex md:flex-row flex-col w-80 ${navbarPosition} md:right-auto md:w-full h-screen md:h-auto py-4 px-8 lg:space-x-4  md:space-x-2 bg-white z-20 justify-between md:items-center shadow-md`}
              className={`flex flex flex-row w-80 ${navbarPosition} right-auto w-full h-auto py-4 px-8 lg:space-x-4 space-x-2 bg-white z-20 justify-between items-center shadow-md border border-b-1 border-gray-lightest`}
            >
              <button className="absolute w-16 h-16 flex justify-center items-center top-0 -left-16 bg-petrol active:bg-petrol-darker rounded-bl-md focus:outline-none focus:bg-petrol-darker">
                {this.state.isNavbarOpen ? (
                  <CloseIcon
                    className="m-4 h-12 fill-current text-white"
                    onClick={this.toggleMenu}
                  />
                ) : (
                  <HamburgerIcon
                    className="m-4 h-12 fill-current text-white"
                    onClick={this.toggleMenu}
                  />
                )}
              </button>
              <Link to="/" className="hidden md:block">
                <Logo
                  onClick={this.navigateToHome}
                  className="w-14 h-14 fill-current text-gray "
                ></Logo>
              </Link>
              {/* <h1 className="text-gray uppercase tracking-wide my-8 md:my-0 text-lg sm:text-xl"> */}
              <h1 className="hidden md:block text-gray uppercase tracking-wide my-8 md:my-0 text-lg sm:text-xl">
                <Link to="/">wettermelden.at</Link>
              </h1>

              {/* <div className="flex-1 md:flex-col md:flex-col-reverse"></div> */}
              <div className="flex-1 flex-col flex-col-reverse relative top-0 md:top-11 z-30">
                {/* <div className="md:flex flex-1 text-center hidden"> */}
                <div className="flex flex-1 text-center hidden">
                  {renderHeaderLeft && renderHeaderLeft()}
                </div>
                {/* <div className="md:flex relative"> */}
                <div className="flex relative flex-row justify-end">
                  {/* <div className="flex md:flex-row flex-col md:absolute z-30 top-2 right-12 md:space-x-4"> */}
                  <div className="flex flex-row space-x-2 sm:space-x-4">
                    {renderHeaderRight && renderHeaderRight()}

                    {/* {!this.state.isNavbarOpen && ( */}
                    {true && (
                      <button className=" btn-icon shadow-md md:bg-white">
                        <HamburgerIcon
                          className="h-12 fill-current text-gray"
                          onClick={this.toggleMenu}
                        />
                      </button>
                    )}
                  </div>
                </div>
                {this.state.isHamburgerMenuOpen && (
                  // <div className="md:absolute md:w-96 md:shadow-md px-4 md:px-8 py-4 md:pt-12 bg-white vh-30 right-0 top-20 relative mt-auto">
                  <div className="absolute z-10 w-full sm:w-96 shadow-md px-8 py-4 pt-12 bg-white vh-30 right-0 top-20 mt-auto">
                    <ul className="list-none">
                      {/* <li className="border-t flex justify-between md:border-none border-gray-lighter py-4 text-gray"> */}
                      <li className="flex justify-between border-none py-4 text-gray items-center">
                        {user.username ? (
                          <React.Fragment>
                          <Link className="text-gray flex flex-row space-x-4 items-center" to="/my/">
                            <button className="btn-icon bg-petrol text-white active:bg-petrol-darker">
                              <UserIcon />
                            </button>
                            <div>
                              {user.username}
                            </div>
                          </Link>
                          <Link to="/my/">
                            <ChevronRightIcon className="h-4 w-4 text-gray fill-current" />
                          </Link>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          <Link className="text-gray flex flex-row space-x-4 items-center" to="/login/">
                            <button className="btn-icon bg-petrol text-white active:bg-petrol-darker">
                              <UserIcon />
                            </button>
                            <div>{t("SCREENS.LOGIN.TITLE")}</div>
                          </Link>
                          <Link to="/login/">
                            <ChevronRightIcon className="h-4 w-4 text-gray fill-current" />
                          </Link>
                          </React.Fragment>
                        )}

                      </li>
                      <li className="border-t flex justify-between border-gray-lighter py-4">
                        <Link to="/settings/" className="text-gray">
                          {t("NAVBAR.TOOLTIP.SETTINGS")}
                        </Link>{" "}
                        <Link to="/settings/">
                          <ChevronRightIcon className="h-4 w-4 float-right text-gray fill-current" />
                        </Link>
                      </li>
                      <li className="border-t flex justify-between border-gray-lighter py-4">
                        <a
                          className="text-gray"
                          href="https://trustedspotter.eu/reporting-parameters"
                          target="_new"
                        >
                          {t("NAVBAR.TOOLTIP.INFO")}
                        </a>{" "}
                        <a
                          href="https://trustedspotter.eu/reporting-parameters"
                          target="_new"
                        >
                          <ChevronRightIcon className="h-4 w-4 text-gray fill-current" />
                        </a>
                      </li>
                      <li className="border-t border-gray-lighter py-4">
                        <Facebook url={window.location.href} />
                      </li>
                      <li>
                        <Twitter url={window.location.href} />
                      </li>
                      <li>
                        <Email url={window.location.href} subject="wettermelden.at" />
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            </div>
          );
        }}
      </Context.Consumer>
    );
  }
}

export default Navbar;
