import React, { Component, Fragment } from "react";
import ListItemLayoutView from "./ListItemLayoutView";
import { Map, Marker } from "../lib/Map";
import { regionSurroundingPosition } from "../lib/utils/regionMath";
import { ReactComponent as ClockIcon } from "../assets/clock.svg";
import { ReactComponent as PinIcon } from "../assets/pin.svg";
import { ReactComponent as QuoteIcon } from "../assets/quote.svg";
import magnifyingGlassIcon from '../assets/iconic/magnifying-glass.svg'
import './ReportDetailsView.scss'
import { Dialog, Transition } from '@headlessui/react'
import { ReactComponent as CrossIcon } from "../assets/cross.svg"

class ReportDetailsView extends Component {
  constructor(props) {
    super(props);

    const { report, reportBuilder } = props;
    reportBuilder.report = report;

    this.state = {
      open: false
    }
  }

  render() {
    const { report, reportPresentation, t, locale } = this.props;

    const { imageSmallUrl, imageUrl, note, longitude, latitude } = report;
    const hasImage = !!imageSmallUrl;
    const position = { longitude, latitude };
    const region = regionSurroundingPosition(position);

    const icon = reportPresentation.getImageForReport(report);

    let title = reportPresentation.getTitleForReport(report);
    if (title) title = t(title);

    const subtitle = this.getReportSubtitle();

    const geolocationString =
      reportPresentation.getGeolocationStringForReport(report);

    const timeString = reportPresentation.getDateTimeStringForReport(
      report,
      locale
    );

    const hasNote = !!note;
    const hasNotesText = t("SCREENS.REPORT_LIST.WITH_NOTE");

    return (
      <ListItemLayoutView
        iconUrl={icon}
        isActive={false}
        showLineTop={false}
        showLineBottom={false}
      >
        <div>
          <p>
              { timeString &&
                  <span>
                      <ClockIcon className="h-4 mr-2 inline-block text-gray stroke-current fill-current" />
                      { timeString }
                  </span>
              }

              { geolocationString &&
                  <span>
                      <PinIcon className="h-4 mx-2 ml-6 inline-block text-gray stroke-current fill-current" />
                      { geolocationString }
                  </span>
              }
          </p>
          <h2 className="text-lg sm:text-xl font-bold">{title}</h2>
          {subtitle && <p className="py-4">{subtitle}</p>}
        </div>
        {hasNote && (
          <div>
            <div>
              <QuoteIcon className="h-12 text-gray stroke-current fill-current" />
              {hasNotesText}:
            </div>
            <div>{note}</div>
          </div>
        )}
        {hasImage && (
          <button className="report-details-view__image block my-8 relative" onClick={() => this.setOpen(true)}>
            <img src={imageSmallUrl} alt={title} />
            <div className="report-details-view__button absolute bottom-2 right-2 bg-black bg-opacity-50 p-2 rounded-md">
              <img data-src={magnifyingGlassIcon} className="iconic iconic--inverted" alt="magnify" />
            </div>
          </button>
        )}

        {/* details */}

        {this.renderImageModal(hasImage, imageUrl, title)}



        <div className="relative w-64 h-64 sm:w-96 sm:h-96 pointer-events-none border border-gray">
          <Map
            region={region}
            showsUserLocation
            zoomEnabled={false}
            zoomControlEnabled={false}
            scrollEnabled={false}
          >
            <Marker coordinate={position} />
          </Map>
        </div>
      </ListItemLayoutView>
    );
  }

  componentDidMount() {
    window.IconicJS().inject(".report-details-view__image img.iconic");
  }

  getReportSubtitle() {
    const { reportPresentation, report, t } = this.props;

    let subtitle = reportPresentation.getSubtitleForReport(report);
    if (!subtitle || subtitle.length === 0) return null;

    subtitle = t(subtitle);
    if (!subtitle || subtitle.length === 0) return null;
    return subtitle;
  }

  renderImageModal(hasImage, imageUrl, title) {
    const {  t } = this.props;
    return this.state.modalOpen && !hasImage ? (<div></div>) : (
      <Transition.Root show={this.state.open || false} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => this.setOpen()}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <img src={imageUrl} alt={title} />
              </div>

              <div className="absolute top-0 right-0">
              {/* <button onClick={() => this.setOpen(false)} title={t('NAVBAR.TOOLTIP.BACK')} className="md:my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full"> */}
              <button onClick={() => this.setOpen(false)} title={t('NAVBAR.TOOLTIP.BACK')} className="my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full">
                {/* <CrossIcon className="fill-current text-white" /><span className="md:hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span> */}
                <CrossIcon className="fill-current text-white" /><span className="hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span>
              </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    )
  }

  setOpen(value) {
    this.setState({ open: value })
  }
}

export default ReportDetailsView;
