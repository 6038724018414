import React, { Fragment } from 'react'

const SectionList = (props) => {
    const { sections, rowRenderer, sectionHeaderRenderer, className } = props

    return (
        <div className={`section-list ${className}`}>
            {
                sections.map((section, sectionIndex) => {
                    const { title, subtitle, data } = section
                    const sectionKey = `section-header-${sectionIndex}`
                    const nSections = sections.length
                    const nRows = data.length
                    return (
                        <Fragment key={sectionKey}>
                            {sectionHeaderRenderer({ title, subtitle, sectionIndex, key: sectionKey, nSections })}
                            {data.map((item, rowIndex) => {
                                const key = `row-${sectionIndex}-${rowIndex}`
                                return rowRenderer({ item, sectionIndex, rowIndex, key, nRows })
                            })}
                        </Fragment>
                    )
                })
            }
        </div>
    )
}

export default SectionList
