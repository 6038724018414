import { Control } from 'ol/control.js'
import mapMarker from '../../assets/location.svg'

const LocationControl = (function (Control) {
  function LocationControl (opt_options) {
    const options = opt_options || {}

    const button = document.createElement('button')
    button.innerHTML = `<img class="ol-location" src="${mapMarker}">`
    // window.IconicJS().inject('.location-icon')
    var element = document.createElement('div')
    element.className = 'go-to-location ol-unselectable ol-control'
    element.appendChild(button)
    element.style.top = '65pt'
    element.style.left = '0.5em'

    Control.call(this, {
      element: element,
      target: options.target
    })

    button.addEventListener('click', () => {
      if (this.handleLocation) this.handleLocation()
    }, false)
  }

  if (Control) LocationControl.__proto__ = Control
  LocationControl.prototype = Object.create(Control && Control.prototype)
  LocationControl.prototype.constructor = LocationControl

  return LocationControl
}(Control))

export default LocationControl
