const config = {
  reportsApiRoot: 'https://reports.wettermelden.at/v2',
  appApiRoot: '/api/v1',
  idApiRoot: 'https://id.wettermelden.at/v1',
  idRoot: 'https://id.wettermelden.at',
  cmsApiRoot: 'https://trustedspotter.eu/api',
  tileServer: 'https://storage.googleapis.com/assets-wettermelden/tiles/wettermelden-bright/{z}/{x}/{y}.png',
  timeouts: {
    createReport: 1000 * 10, // timeout for creating report in ms
    uploadReportImage: 1000 * 60, // timeout for uploading report image in ms
    queryReports: 1000 * 10, // timeout for querying reports
    positionDetection: 1000 * 10,
    default: 1000 * 10
  },
  mqtt: {
    host: 'wettermelden.at',
    port: 8081,
    useSSL: true,
    topic: 'notifications'
  },
  fallbackRegion: {
    latitude: 47.31421763436203,
    longitude: 13.26312412067059,
    latitudeDelta: 10,
    longitudeDelta: 10,
    zoom: 8
  },
  positionSurroundings: {
    latitudeDelta: 0.2,
    longitudeDelta: 0.2
  },
  timespan: 3 * 3600, // fetch reports back until timespan ago, in seconds
  positionDetectionOptions: {
    timeout: 1000 * 20
    // enableHighAccuracy: true
  },
  requiredAccuracy: 2000, // in meters
  userLocMaxAge: 5 * 60, // in seconds
  locale: 'de'
}

export default config
