import Ajv from 'ajv'
import assert from 'browser-assert'

/*
ReportBuilder class

Facilitates the creation and validation of reports using json schema definitions.

example usage:

  const builder = new ReportBuilder(baseSchema, [ eventSpecificSchema1, eventSpecificSchema2 ]);

  // set some basic report properties
  builder.longitude = 3.56;
  builder.latitude = 6.34;
  builder.note = 'light rain';

  builder.eventTypes; // returns list of possible event types
  // example return value:
  // [ 'noPrecipitation',
  //   'rainOrSnow',
  //   'hail',
  //   'severeWind',
  //   'whirlWind',
  //   'reducedVisibility',
  //   'closeLightning',
  //   'flood',
  //   'freshSnowCover',
  //   'landslideAndAvalanche',
  //   'other' ]

  // chose from list and set event type
  const eventType = 'rainOrSnow';
  builder.eventType = eventType;

  // get parameter types specific to event type
  const requiredSubParams = builder.requiredEventSpecificParameterTypes;
  // example return value:
  // {
  //   "rainOrSnowType": {
  //     "type": "string",
  //     "enum": [
  //         "drizzle",
  //         "freezingDrizzle",
  //         "rain",
  //         "freezingRain",
  //         "icePelletsOrSnowGrainsOrGraupel",
  //         "snowfall",
  //         "mixedRainAndSnowfall"
  //     ]
  //   }
  // }

  // chose from list and set parameter specific to event type
  const name = 'rainOrSnowType';
  const value = 'drizzle';
  builder[name] = value;

  // validate and get report
  if (builder.validate()) {
    const report = builder.report;
    // example return value
    // {
    //   longitude: 3.45,
    //   latitude: 6.34,
    //   note: 'light rain'
    //   eventType: 'rainOrSnow',
    //   rainOrSnowType: 'drizzle'
    // }
  } else {
    const errors = builder.validationErrors;
  }

There are two kinds of json schemas for reports:
1. base schema
2. sub schema

The base schema is common to reports of all event types.

The sub schema that will be used depends on the value of the eventType property of the report.

Properties of the report to be built can be set directly on the report builder:
  reportBuilder.longitude = 16.34;
  reportBuilder.note = 'heavy storm';
*/
class ReportBuilder {
  // Constructs a report builder.
  //
  // baseSchema is the base schema of the report, common to all event types
  // eventSpecificSchemas is an array of event specific schemas, each one specific to a certain event type
  //
  // The sub schemas must contain a constant property eventType, for example:
  // "eventType": { "const": "hail" },
  // The eventType property of the sub schema is used to switch the sub schema
  // based on the eventType property of the report, for example eventType = "hail" will use the sub schema
  // containing the line shown above.
  constructor (baseSchema, eventSpecificSchemas) {
    assert(baseSchema, 'base schema is required')
    assert(eventSpecificSchemas, 'sub schemas are required')

    this.baseSchema = baseSchema
    this.eventSpecificSchemas = eventSpecificSchemas

    // index sub schemas by event type
    if (!Array.isArray(this.eventSpecificSchemas)) {
      this.eventSpecificSchemas = [ this.eventSpecificSchemas ]
    }
    this.eventSpecificSchemas = indexEventSpecificSchemas(this.eventSpecificSchemas)

    // json schema validator
    this.validator = new Ajv()
    this._validateBaseSchema = this.validator.compile(this.baseSchema)
    this._validateEventSpecificSchema = null

    this.emptyReportBuilderObjectProperties = []
    this.emptyReportBuilderObjectProperties = Object.keys(this)
  }

  reset () {
    for (let key of Object.keys(this)) {
      if (!this.emptyReportBuilderObjectProperties.includes(key)) {
        delete this[key]
      }
    }
  }

  resetEventType () {
    for (let key of Object.keys(this)) {
      if (
        !this.emptyReportBuilderObjectProperties.includes(key) &&
        !this.baseParameterNames.includes(key)
      ) {
        delete this[key]
      }
    }

    delete this['eventType']
  }

  // gets a list of possible event types
  get eventTypes () {
    return this.baseSchema.properties.eventType.enum
  }

  /*
  gets type definitions of required sub parameters
  the format of the type definitions is the same as in json schema

  this method may only be called if the event type of the report has been set,
  otherwise an exception will be thrown
  */
  get requiredEventSpecificParameterTypes () {
    assert(this.eventType, 'event type must be set before calling this method')
    return getRequiredParameterTypesOfSchema(this.eventSpecificSchema)
  }

  getRequiredEventSpecificParameterTypes () {
    const types = this.requiredEventSpecificParameterTypes
    const typesArray = []
    for (let key in types) {
      if (types.hasOwnProperty(key)) {
        typesArray.push(key)
      }
    }

    return typesArray
  }

  getRequiredEventSpecificParameterType () {
    const types = this.getRequiredEventSpecificParameterTypes()

    if (types.length === 0) return null

    return types[0]
  }

  /* gets the names of the base parameters (required and optional) */
  get baseParameterNames () {
    return getParameterNamesOfSchema(this.baseSchema)
  }

  /*
  validates the report
  returns true if the report is valid, false otherwise

  if the event type has already been set, sub schema validation is
  performed in addition to base schema validation
  */
  validate () {
    // validate base schema
    const baseSchemaValid = this.validateBaseSchema(this)

    // if event type is not set or not valid we're done
    if (!this.eventType || !this.eventTypes.includes(this.eventType)) {
      return baseSchemaValid
    }

    // validate sub schema
    const eventSpecificSchemaValid = this.validateEventSpecificSchema(this)
    return baseSchemaValid && eventSpecificSchemaValid
  }

  // gets the errors, if any, of the last call to the validate method
  get validationErrors () {
    // errors of base schema validation
    let baseErrors = []
    if (this._validateBaseSchema) {
      baseErrors = this._validateBaseSchema.errors || []
    }

    // if there is no event type we're done
    if (
      !this.eventType
    ) {
      return baseErrors
    }

    // errors of sub schema validation
    let subErrors = []
    if (this._validateEventSpecificSchema) {
      subErrors = this._validateEventSpecificSchema.errors || []
    }

    return [
      ...baseErrors,
      ...subErrors
    ]
  }

  validateBaseSchema () {
    return this._validateBaseSchema(this)
  }

  validateEventSpecificSchema () {
    assert(this.eventType, 'event type must be set before calling this method')

    // validate sub schema
    this._validateEventSpecificSchema = this.validator.compile(this.eventSpecificSchema)

    return this._validateEventSpecificSchema(this)
  }

  allRequiredEventSpecificParametersSet () {
    const parameters = Object.keys(this.requiredEventSpecificParameterTypes)
    for (let param of parameters) {
      if (this[param] === undefined) {
        return false
      }
    }
    return true
  }

  // gets the report as a plain javascript object
  get report () {
    // base property names
    const basePropertyNames = getParameterNamesOfSchema(this.baseSchema)

    // sub property names
    let subPropertyNames = []
    if (this.eventType) {
      subPropertyNames = getParameterNamesOfSchema(this.eventSpecificSchema)
    }

    // all property names
    const propertyNames = [
      ...basePropertyNames,
      ...subPropertyNames
    ]

    // build report
    let report = {}
    for (let objectPropertyName in this) {
      if (propertyNames.includes(objectPropertyName)) {
        report[objectPropertyName] = this[objectPropertyName]
      }
    }

    return report
  }

  // sets the report from a plain javascript object
  set report (report) {
    Object.assign(this, report)
  }

  // private

  /*
  gets the sub schema of the report

  this method may only be called if the event type of the report has been set,
  otherwise an exception will be thrown
  */
  get eventSpecificSchema () {
    assert(this.eventType, 'event type must be set before calling this method')
    assert(
      this.eventTypes.includes(this.eventType) &&
      this.eventSpecificSchemas[this.eventType],
      'missing schema for event type')

    return this.eventSpecificSchemas[this.eventType]
  }
}

/*
creates a map from an array of report schemas
the key of the map is the property properties.eventType.const of the sub schema
*/
function indexEventSpecificSchemas (eventSpecificSchemas) {
  let index = {}
  for (let schema of eventSpecificSchemas) {
    const eventType = schema.properties.eventType.const
    index[eventType] = schema
  }
  return index
}

// gets definitions of required parameter types of json schema
function getRequiredParameterTypesOfSchema (schema) {
  const required = schema.required || []
  let parameterTypes = {}
  for (let param of required) {
    parameterTypes[param] = schema.properties[param]
  }
  return parameterTypes
}

// gets names of all parameters (required and optional) of json schema
function getParameterNamesOfSchema (schema) {
  const parameterNames = Object.keys(schema.properties)
  return parameterNames
}

export default ReportBuilder
