import React, { PureComponent } from 'react'
// import quoteIcon from '../assets/iconic/double-quote-serif.svg'
// import './ReportsListItem.scss'
import ListItemLayoutView from './ListItemLayoutView'
import { Map, Marker } from '../lib/Map'
import { regionSurroundingPosition } from '../lib/utils/regionMath'
import { ReactComponent as ClockIcon } from "../assets/clock.svg"
import { ReactComponent as PinIcon } from "../assets/pin.svg"
import { ReactComponent as QuoteIcon } from "../assets/quote.svg"

class ReportsListItem extends PureComponent {
    render () {
        const { report, reportPresentation, className, t, locale,
            isFirst, isLast, isOnly
        } = this.props

        const { imageSmallUrl, imageUrl, note, longitude, latitude } = report
        const hasImage = !!imageSmallUrl
        const position = { longitude, latitude }
        const region = regionSurroundingPosition(position)

        const icon = reportPresentation.getImageForReport(report)

        let title = reportPresentation.getTitleForReport(report)
        if (title) title = t(title)

        const subtitle = this.getReportSubtitle()

        const geolocationString = reportPresentation.getGeolocationStringForReport(report)

        const timeString = reportPresentation.getDateTimeStringForReport(report, locale)

        const hasNote = !!note
        const noteClass = hasNote && hasImage ? 'reports-list-item__comments-indicator--overlay' : ''
        const hasNotesText = t('SCREENS.REPORT_LIST.WITH_NOTE')

        const showLineTop = !isOnly && !isFirst
        const showLineBottom = !isOnly && !isLast
        return (
            <ListItemLayoutView
                text={title}
                iconUrl={icon}
                isActive={false}
                showLineTop={showLineTop}
                showLineBottom={showLineBottom}
            >

                <p className="">
                    { timeString &&
                        <span>
                            <ClockIcon className="h-4 mr-2 inline-block text-gray stroke-current fill-current" />
                            { timeString }
                        </span>
                    }

                    { geolocationString &&
                        <span>
                            <PinIcon className="h-4 mx-2 ml-6 inline-block text-gray stroke-current fill-current" />
                            { geolocationString }
                        </span>
                    }
                </p>
                <h2 className="text-lg sm:text-xl py-2 font-bold">{title}</h2>
                {subtitle && <p className="">{subtitle}</p>}



                {   hasNote &&
                        <div className={`media-right reports-list-item__comments-indicator ${noteClass}`}>
                            <QuoteIcon className="h-12 text-gray stroke-current fill-current" />
                            {hasNotesText}:
                            <div className="my-2">{note}</div>
                        </div>
                }
                {
                    hasImage &&
                        <div className="media-right">
                            <div className="reports-list-item__image">
                                <img src={imageSmallUrl} alt={title} />
                            </div>
                        </div>
                }

                {/* details */}
                {/* for now keep logic: separate details screen and url, no collapse/uncollapse as in new design */}

                {/* <div className="mt-2">{'Details:'}</div>

                {
                    hasImage &&
                        <div className="media-right">
                            <div className="reports-list-item__image">
                                <img src={imageUrl} alt={title} />
                            </div>
                        </div>
                }

                <div>{note}</div>

                <div className="relative w-96 h-96 pointer-events-none border border-gray">
                    <Map
                        region={region}
                        showsUserLocation
                        zoomEnabled={false}
                        zoomControlEnabled={false}
                        scrollEnabled={false}
                    >
                        <Marker coordinate={position} />
                    </Map>
                </div> */}
            </ListItemLayoutView>
        )
    }

    componentDidMount () {
      window.IconicJS().inject('.reports-list-item img.iconic')
    }

    getReportSubtitle () {
        const { reportPresentation, report, t } = this.props

        let subtitle = reportPresentation.getSubtitleForReport(report)
        if (!subtitle || subtitle.length === 0) return null

        subtitle = t(subtitle)
        if (!subtitle || subtitle.length === 0) return null
        return subtitle
    }
}

export default ReportsListItem
