import React, { Component } from "react";

import { ReactComponent as ListIcon } from "../assets/list.svg";
import { ReactComponent as MapIcon } from "../assets/map.svg";

// import './ActivityIndicator.scss'
class ViewSwitcher extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { mapLabel, listLabel, title, active, onClick } = this.props;

    return (
      <div>
        {/* <p className="text-gray md:hidden">{title}</p> */}
        <p className="text-gray hidden">{title}</p>
        {/* <div className="flex my-4 md:my-0 shadow-md"> */}
        <div className="flex my-0 shadow-md">
          <button
            onClick={() => onClick("map")}
            className={
              active === "map"
                ? "btn-switch text-white bg-red active:bg-red-darker rounded-l-md"
                : "btn-switch text-gray-lighter bg-white active:bg-gray-lightest border-gray-lighter active:border-2 rounded-l-md"
            }
          >
            <MapIcon
              className={
                active === "map"
                  ? "fill-current text-white"
                  : "fill-current  text-gray"
              }
            />
            {/* <span className="block whitespace-nowrap md:hidden overflow-ellipsis"> */}
            <span className="whitespace-nowrap hidden overflow-ellipsis">
              {mapLabel}
            </span>
          </button>
          <button
            onClick={() => onClick("list")}
            title={listLabel}
            className={
              active === "list"
                ? "btn-switch text-white bg-red active:bg-red-darker rounded-r-md"
                : "btn-switch text-gray-lighter bg-white active:bg-gray-lightest border-gray-lighter active:border-2 rounded-r-md"
            }
          >
            <ListIcon
              className={
                active === "list"
                  ? "fill-current text-white"
                  : "fill-current  text-gray"
              }
            />
            {/* <span className="block whitespace-nowrap md:hidden overflow-ellipsis"> */}
            <span className="whitespace-nowrap hidden overflow-ellipsis">
              {listLabel}
            </span>
          </button>
        </div>
      </div>
    );
  }
}
export default ViewSwitcher;
