import TileLayer from 'ol/layer/Tile'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
// import Feature from 'ol/Feature'
import OSM from 'ol/source/OSM'
import XYZ from 'ol/source/XYZ'
import { Fill, Stroke, Style, Icon, Text } from 'ol/style'
import * as olColor from 'ol/color'

function colorWithAlpha(c, alpha) {
  const [r, g, b] = Array.from(olColor.asArray(c));
  return olColor.asString([r, g, b, alpha]);
}

const osm = new TileLayer({
  source: new OSM()
})

const zamg = (tileServer) => new TileLayer({
  title: 'zamg',
  visible: true,
  source: new XYZ({
    url: tileServer,
  })
})

// 1=storm, 2=rain, 3=snow, 4=black ice, 5=thunderstorm, 6=heat, 7=cold
const WARNING_TYPES = {
  1: {
    label: 'wind',
    icon: 'https://cms-visualization.meteoalarm.org/content/assets/1.svg'
  },
  3: {
    label: 'snow-ice',
    icon: 'https://cms-visualization.meteoalarm.org/content/assets/2.svg'
  },
  4: {
    label: 'snow-ice',
    icon: 'https://cms-visualization.meteoalarm.org/content/assets/2.svg'
  },
  5: {
    label: 'thunderstorm',
    icon: 'https://cms-visualization.meteoalarm.org/content/assets/3.svg'
  },
  // 4: {
  //   label: 'Fog',
  //   icon: 'https://cms-visualization.meteoalarm.org/content/assets/4.svg'
  // },
  6: {
    label: 'high-temperature',
    icon: 'https://cms-visualization.meteoalarm.org/content/assets/5.svg'
  },
  7: {
    label: 'low-temperature',
    icon: 'https://cms-visualization.meteoalarm.org/content/assets/6.svg'
  },
  // 7: {
  //   label: 'coastalevent',
  //   icon: 'https://cms-visualization.meteoalarm.org/content/assets/7.svg'
  // },
  // 8: {
  //   label: 'forest-fire',
  //   icon: 'https://cms-visualization.meteoalarm.org/content/assets/8.svg'
  // },
  // 9: {
  //   label: 'avalanches',
  //   icon: 'https://cms-visualization.meteoalarm.org/content/assets/9.svg'
  // },
  2: {
    label: 'Rain',
    icon: 'https://cms-visualization.meteoalarm.org/content/assets/10.svg'
  },
  // 12: {
  //   label: 'flooding',
  //   icon: 'https://cms-visualization.meteoalarm.org/content/assets/12.svg'
  // },
  // 13: {
  //   label: 'rain-flood',
  //   icon: 'https://cms-visualization.meteoalarm.org/content/assets/13.svg'
  // }
}

const WARNING_LEVELS = {
  1: {
    label: 'all clear',
    color: 'green',
    rank: 10
  },
  2: {
    label: 'moderate',
    color: '#ffda23',
    rank: 20
  },
  3: {
    label: 'Severe',
    color: '#ff9400',
    rank: 30
  },
  4: {
    label: 'Extreme',
    color: '#ff0000',
    rank: 40
  }
}

const vectorSource = new VectorSource({
  features: []
})

const warningStatus = () => new VectorLayer({
  source: vectorSource,
  style: function(feature, resolution) {
    const properties = feature.getProperties();
    const { wlevel, wtype } = properties
    // const warningType = WARNING_TYPES[wtype]
    const warningLevel = WARNING_LEVELS[wlevel + 1]

    return new Style({
      stroke: new Stroke({
        color: '#eeeeee',
        width: '1'
      }),
      fill: new Fill({
        color: colorWithAlpha(warningLevel.color, 0.2)
      })
    })
  }
})

const vectorSourceIcons = new VectorSource({
  features: []
})

const warningStatusIcons = () => new VectorLayer({
  source: vectorSourceIcons,
  style: function(feature, resolution) {
    const properties = feature.getProperties();
    const { wtype } = properties
    console.log('wtype', wtype);
    const warningType = WARNING_TYPES[wtype]

    return new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: warningType.icon,
        scale: 0.75,
      }),
      text: new Text({
        text: warningType.label,
        scale: 1.3,
        offsetY: 30
      })
    })
  }
})

export { zamg, warningStatus, warningStatusIcons }
