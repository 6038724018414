function timespanStringToSeconds (timespan) {
  const map = {
    '3h': 3 * 3600,
    '12h': 12 * 3600,
    '24h': 24 * 3600,
    '48h': 48 * 3600,
    '72h': 72 * 3600
  }

  return map[timespan]
}

export {
  timespanStringToSeconds
}
