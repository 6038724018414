import React from 'react';
import { Provider } from './models/Context'
import AppRouter from './AppRouter'
import './App.scss';

function App() {
  return (
    <div className="text-gray h-full">
      <Provider>
        <AppRouter/>
      </Provider>
    </div>
  )
}

export default App
