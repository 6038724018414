const Typohgraphy = () => (
    <div className="bg-gray-lightest min-h-screen py-4">
        <div className="container mx-auto px-4 bg-gray-lightest">
            <h1 className="text-2xl sm:text-5xl text-gray font-bold">Heading 1</h1>
            <h2 className="text-xl sm:text-3xl text-gray font-bold my-4">Heading 2</h2>
            <h2 className="text-lg sm:text-xl text-gray font-bold my-4">Heading 3</h2>
            <p className="text-gray my-4">Base text. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, inventore iure deserunt cumque consequatur distinctio magni facilis hic quaerat eum?</p>
            <p className="text-gray my-4">Base text. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, inventore iure deserunt cumque consequatur distinctio magni facilis hic quaerat eum?</p>
            <p className="text-xs text-gray my-4">Small text. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda, inventore iure deserunt cumque consequatur distinctio magni facilis hic quaerat eum?</p>
        </div>
    </div>
)

export default Typohgraphy
