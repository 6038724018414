import assert from 'browser-assert'

class NewsletterService {
  constructor (newsletterSubscribeUrl) {
    this.newsletterSubscribeUrl = newsletterSubscribeUrl
  }

  async subscribe (email) {
    assert(email, 'email is required')

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

    // body as json
    const body = JSON.stringify({ EMAIL: email })

    const response = await fetch(this.newsletterSubscribeUrl, {
      headers,
      body,
      method: 'POST'
    })
    return response.json()
  }
}

export default NewsletterService
