import React, { Component } from "react";

class ListItemLayoutView extends Component {
  render() {
    const { text, iconUrl, isActive, showLineTop, showLineBottom } = this.props;
    return (
      <div className="flex flex-row flex-wrap sm:flex-nowrap">
        <div className="flex flex-col items-stretch">
          <div className={`flex justify-center ${showLineTop ? 'h-16' : 'sm:h-16' } pr-6`}>
            {showLineTop && (
              <span className="border-r-2 w-0 border-gray-superlight"></span>
            )}
          </div>
          <div className="flex align-center justify-center py-2" onClick={this.props.onClick ? this.props.onClick : () => {}}>
            {iconUrl ? (
              <span className=" h-16 w-16 flex">
              <img className=" " src={iconUrl} alt={text} />
              </span>
            ) : (
              <span
                className={
                  isActive
                    ? "bg-white border-2 border-red text-red border-solid rounded-full h-16 w-16 flex items-center justify-center text-xl sm:text-3xl font-bold"
                    : "bg-white border-2 border-lightblue text-lightblue border-solid rounded-full h-16 w-16 flex items-center justify-center text-xl sm:text-3xl font-bold"
                }
              >
                {text}
              </span>
            )}
            <div className="arrow-left mt-4 pl-4 opacity-0 sm:opacity-100"></div>
          </div>
          <div className="flex justify-center pr-6 flex-1">
            {showLineBottom && (
              <div className="border-r-2 w-0 border-gray-superlight ">&nbsp;</div>
            )}
          </div>
        </div>
        <div className="bg-white w-full p-4 sm:p-8 shadow-sm mt-4">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ListItemLayoutView;
