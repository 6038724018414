import React, { Component } from "react";
import ListItemLayoutView from "./ListItemLayoutView";
import ParameterView from "./ParameterView";

class ParameterSelectionView extends Component {
  render() {
    const {
      title,
      subtitle,
      parameters,
      step,
      showLineTop,
      showLineBottom,
      onParameterSelected,
    } = this.props;

    return (
      <ListItemLayoutView
        text={step}
        isActive={true}
        showLineTop={showLineTop}
        showLineBottom={showLineBottom}
      >
        {
          (title || subtitle) && (
            <div className="px-4 py-8">
              { title && (<h2 className="text-lg sm:text-xl font-bold">{title}</h2>)}
              { subtitle && (<p className="py-4">{subtitle}</p>)}
            </div>
          )
        }
        <div className="flex  flex-wrap">
        {parameters.map((parameter, index) => {
          const key = `select-parameter-${index}`;
          return (
            <a
              onClick={() => {
                onParameterSelected(parameter);
              }}
              key={key}
              className="w-full md:w-1/2"
            >
              <ParameterView
                parameter={parameter}
                showLineTop={showLineTop}
                showLineBottom={showLineBottom}
              />
            </a>
          );
        })}
        </div>
      </ListItemLayoutView>
    );
  }
}

export default ParameterSelectionView;
