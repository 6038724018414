import React, { Component } from 'react'
import { format, isToday, isYesterday } from 'date-fns'
import { Link } from 'react-router-dom'
import SectionList from '../lib/SectionList'
import ReportsListItem from './ReportsListItem'
// import './ReportsListView.scss'

class ReportsListView extends Component {
  constructor (props) {
    super(props)

    this.renderHeader = this.renderHeader.bind(this)
    this.renderRow = this.renderRow.bind(this)
  }

  render () {
    const { reports, className, t } = this.props

    const sections = this.prepareSections()

    return reports.length === 0 ? (
        <div className={`reports-list reports-list--empty ${ className || ''}`}>
          { t('SCREENS.REPORT_LIST.NO_REPORTS') }
        </div>
      ) : (
      <div className={`reports-list ${ className || ''}`}>
        <h2 className="text-xl sm:text-3xl font-bold">{t('SCREENS.REPORT_LIST.TITLE')}</h2>
        <p className="py-4">{t('SCREENS.REPORT_LIST.SUBTITLE')}</p>
        <SectionList
          sections={sections}
          sectionHeaderRenderer={this.renderHeader}
          rowRenderer={this.renderRow}
          className={`reports-list ${ className || ''}`}
        />
     </div>
    )
  }

  renderHeader ({title, sectionIndex, key, style, isScrolling, isVisible, parent}) {
    return (
      <h3 key={key} className="awob-list-section-header text-lg sm:text-xl bg-gray-light text-gray text-center font-bold my-4 p-2">{title}</h3>
    )
  }

  renderRow ({item, sectionIndex, rowIndex, key, style, isScrolling, isVisible, parent, nRows}) {
    const { reportPresentation, t, locale } = this.props
    const isOnly = nRows === 1
    const isFirst = rowIndex === 0
    const isLast = rowIndex === nRows -1

    return (
      <Link key={key} to={`/reports/${item.uid}`} className="reports-list__link">
        <ReportsListItem
          report={item}
          reportPresentation={reportPresentation}
          t={t}
          className="reports-list__item"
          locale={locale}
          isOnly={isOnly}
          isFirst={isFirst}
          isLast={isLast}
        />
      </Link>
    )
  }

  prepareSections () {
    const { t } = this.props
    const { reports } = this.props
    if (!reports) return { title: 'No data', groups: [] }
    const sections = []
    const groups = reports.reduce((r, a) => {
      let timeStamp = format(a.createdAt, 'DD.MM.YYYY')
      if (isToday(a.createdAt)) {
        timeStamp = t('SCREENS.REPORT_LIST.TODAY')
      } else if (isYesterday(a.createdAt)) {
        timeStamp = t('SCREENS.REPORT_LIST.YESTERDAY')
      }

      if (!r[timeStamp]) sections.push(timeStamp)
      r[timeStamp] = r[timeStamp] || []
      r[timeStamp].push(a)
      return r
    }, Object.create(null))

    return sections.map((section) => { return { title: section, data: groups[section] } })
  }
}

export default ReportsListView
