import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Context from './models/Context'
import LoadingView from './views/LoadingView'

class AppRoute extends Route {
    constructor (props) {
        super(props)

        this.state = {
            isLoading : false,
            gdprAccepted: false
        }
    }

    render () {
        const { authService } = this.context
        const requireAuth = this.props.requireAuth && !authService.isLoggedIn()
        const forbidAuth = this.props.forbidAuth && authService.isLoggedIn()

        const { component: Component, path, ...rest } = this.props

        const renderRoute = (props) => {
            const { isLoading, gdprAccepted } = this.state
            return isLoading ? (
                <LoadingView showSplash={true}/>
            ) : (
                gdprAccepted ? (
                    requireAuth ? (
                        <Redirect to={`/login/?redirect=${this.props.path}`} />
                    ) : (forbidAuth ? (
                        <Redirect to="/reports/" />
                    ) : (
                        <Component {...props} />
                    ))
                ) : (
                    <Redirect to={{ pathname: '/gdpr/', state: { from: props.location }}} />
                )
            )
        }

        return <Route {...rest} render={renderRoute} />
    }

    doRender () {

    }

    async componentWillMount () {
        const { appService } = this.context

        this.setState({ isLoading: true })

        try {
            const gdprAccepted = await appService.hasAcceptedGDPR()

            const delay = new Promise(resolve => setTimeout(resolve, 2000))
            await delay

            this.setState({ gdprAccepted })
        } finally {
            this.setState({ isLoading: false })
        }
    }
}

AppRoute.contextType = Context

export default AppRoute
