import React, { Component } from 'react'
import config from '../config/config'
import { withTimeout, TimeoutError, isOnline } from '../lib/utils/network'
import { errorMessagesFromApiErrors } from '../lib/utils/string'
import lockIcon from '../assets/iconic/lock.svg'
import eyeIcon from '../assets/iconic/eye.svg'

class ProfileView extends Component {
  constructor (props) {
    super(props)

    this.state = {
      changingPassword: false,
      passwordChangeBtnEnabled: false,
      oldPassword: '',
      newPassword: '',
      successMessageForm: '',
      errorMessageForm: '',
      errorMessagePasswordCurrent: '',
      errorMessagePassword: '',
      showOldPassword: false,
      showNewPassword: false
    }

    this.onPwdChange = this.onPwdChange.bind(this)
    this.onOldPasswordChange = this.onOldPasswordChange.bind(this)
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this)
    this.toggleShowNewPassword = this.toggleShowNewPassword.bind(this)
    this.toggleShowOldPassword = this.toggleShowOldPassword.bind(this)
  }

  onTextChange (key, text) {
    const { oldPassword, newPassword } = this.state

    // using local object because setting state is not immediate
    const passwords = { oldPassword, newPassword }
    passwords[key] = text

    this.setState(passwords)

    const maySubmit = passwords.oldPassword && passwords.newPassword
    if (maySubmit && !this.state.passwordChangeBtnEnabled) {
      this.setState({ passwordChangeBtnEnabled: true })
    }
    if (!maySubmit && this.state.passwordChangeBtnEnabled) {
      this.setState({ passwordChangeBtnEnabled: false })
    }
  }

  onOldPasswordChange () {
    this.onTextChange('oldPassword', this.oldPasswordInput.value)
  }

  onNewPasswordChange () {
    this.onTextChange('newPassword', this.newPasswordInput.value)
  }

  async onPwdChange (e) {
    e.preventDefault()

    const { t } = this.props

    if (!isOnline()) {
      this.setState({
        errorMessageForm: t('HELPERS.NETWORK_ERROR')
      })

      return
    }

    const { timeouts } = config

    try {
      this.resetMessages()

      this.setState({
        changingPassword: true,
        passwordChangeBtnEnabled: false
      })

      const { oldPassword, newPassword } = this.state
      const result = await withTimeout(
        timeouts.default,
        new TimeoutError('timeout while changing password'),
        this.props.authService.changePassword(oldPassword, newPassword)
      )
      if (result.success) {
        this.resetForm()
        this.setState({
          successMessageForm: t('SCREENS.PROFILE.CHANGEPWD_SUCCCESS')
        })
      } else {
        const { errors } = result
        const errorMessages = errorMessagesFromApiErrors(errors, 'errorMessage')
        this.setState(errorMessages)
      }
    } catch (e) {
      let message

      if (e instanceof TimeoutError) {
        message = t('HELPERS.NETWORK_ERROR')
      }

      this.setState({
        errorMessageForm: message || t('HELPERS.UNEXPECTED_ERROR')
      })
    } finally {
      this.setState({
        changingPassword: false,
        passwordChangeBtnEnabled: true
      })
    }
  }

  resetForm () {
    this.setState({
      passwordChangeBtnEnabled: false,
      oldPassword: '',
      newPassword: '',
    })
  }

  resetMessages () {
    this.setState({
      successMessageForm: '',
      errorMessageForm: '',
      errorMessagePasswordCurrent: '',
      errorMessagePassword: ''
    })
  }

  render () {
    const { t, user } = this.props
    const { showOldPassword, showNewPassword, passwordChangeBtnEnabled, changingPassword,
      successMessageForm, errorMessageForm, errorMessagePasswordCurrent, errorMessagePassword } = this.state
    const oldPasswordInputType = showOldPassword ? 'text' : 'password'
    const newPasswordInputType = showNewPassword ? 'text' : 'password'
    const oldEyeOpenClass = showOldPassword ? 'hidden' : ''
    const oldEyeClosedClass = showOldPassword ? '' : 'hidden'
    const newEyeOpenClass = showNewPassword ? 'hidden' : ''
    const newEyeClosedClass = showNewPassword ? '' : 'hidden'
    const submitButtonClass = changingPassword ? 'is-loading' : ''

    return (
      <div className="profile-view">
        <div className="container mx-auto px-4 pt-28 md:pt-32 pb-12">
          <form onSubmit={this.onPwdChange} className="flex flex-col space-y-8">
            <div>
              <label className="label">{t('SCREENS.PROFILE.OLD_PASSWORD')}</label>
              <div className="flex w-full input">
                <span className="input-icon">
                  <img data-src={lockIcon} className="iconic iconic-sm iconic--dampened" alt="Kommentar" />
                </span>
                <input className="flex-auto outline-none px-4" type={oldPasswordInputType} ref={(ref) => this.oldPasswordInput = ref} onChange={this.onOldPasswordChange} />
                <span className="input-icon mt-1.5" style={{pointerEvents: 'initial'}}>
                  <button type="button" className="button--link" onClick={this.toggleShowOldPassword}>
                    <span className={oldEyeOpenClass}><img data-src={eyeIcon} data-state="open" className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.SHOW')} aria-hidden="true" /></span>
                    <span className={oldEyeClosedClass}><img data-src={eyeIcon} data-state="closed" className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.HIDE')} aria-hidden="true" /></span>
                  </button>
                </span>
              </div>
              { errorMessagePasswordCurrent.length > 0 && <p className="text-red mt-4">{errorMessagePasswordCurrent}</p> }
            </div>

            <div>
              <label className="label">{t('SCREENS.PROFILE.NEW_PASSWORD')}</label>
              <div className="flex w-full input">
                <span className="input-icon">
                 <img data-src={lockIcon} className="iconic iconic-sm iconic--dampened" alt="Kommentar" />
                </span>
                <input className="flex-auto outline-none px-4" type={newPasswordInputType} ref={(ref) => this.newPasswordInput = ref} onChange={this.onNewPasswordChange} />
                <span className="input-icon mt-1.5" style={{pointerEvents: 'initial'}}>
                  <button type="button" className="button--link" onClick={this.toggleShowNewPassword}>
                    <span className={newEyeOpenClass}><img data-src={eyeIcon} data-state="open" className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.SHOW')} aria-hidden="true" /></span>
                    <span className={newEyeClosedClass}><img data-src={eyeIcon} data-state="closed" className="iconic iconic-sm iconic--dampened" alt={t('HELPERS.HIDE')} aria-hidden="true" /></span>
                  </button>
                </span>
              </div>
              { errorMessagePassword.length > 0 && <p className="text-red mt-4">{errorMessagePassword}</p> }
            </div>

            { successMessageForm.length > 0 && <p className="text-green-700 mt-4">{successMessageForm}</p> }
            { errorMessageForm.length > 0 && <p className="text-red mt-4">{errorMessageForm}</p> }

            <div className="field">
              <div className="control">
                <button type="submit" className={`btn-primary ${submitButtonClass}`} disabled={!passwordChangeBtnEnabled}>{t('SCREENS.PROFILE.CHANGE_PASSWORD_BUTTON')}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

  componentDidMount () {
    window.IconicJS().inject('.profile-view img.iconic')
  }

  toggleShowOldPassword () {
    const { showOldPassword } = this.state
    this.setState({ showOldPassword: !showOldPassword })
  }

  toggleShowNewPassword () {
    const { showNewPassword } = this.state
    this.setState({ showNewPassword: !showNewPassword })
  }
}

export default ProfileView
