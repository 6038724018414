import React, { Component, Fragment } from 'react'
import Navbar from '../components/Navbar'

import chevronIcon from '../assets/iconic/chevron.svg'
import Container from '../views/Container'
import Context from '../models/Context'
import { ReactComponent as CrossIcon } from "../assets/cross.svg"

class SettingsScreen extends Component {
  constructor (props) {
    super(props)

    this.renderNavbarHeaderLeft = this.renderNavbarHeaderLeft.bind(this)
    this.renderNavbarHeaderRight = this.renderNavbarHeaderRight.bind(this)
    this.onBack = this.onBack.bind(this)

    this.onLocaleChange = this.onLocaleChange.bind(this)

    this.state = {
      locale: ''
    }
  }

  componentDidMount () {
    const { translationService } = this.context
    const locale = translationService.getLocale()
    this.setState({ locale })
  }

  render () {
    const { t } = this.context
    const title = t('SCREENS.SETTINGS.TITLE')

    return (
        <div className="">
            <Navbar
              title={title}
              className="app__navbar"
              renderHeaderLeft={this.renderNavbarHeaderLeft}
              renderHeaderRight={this.renderNavbarHeaderRight}
            />
            <Container>
              <div className="container mx-auto px-4 pt-28 md:pt-32 pb-12">
                <div className="section">
                  { this.renderLocaleSelection() }
                </div>
              </div>
            </Container>
        </div>
    )
  }

  renderNavbarHeaderLeft () {
    return (
      <Fragment>

          <button onClick={this.onBack}>
            <img data-src={chevronIcon} className="iconic w-8 h-8" data-direction="left" alt="back" />
          </button>

      </Fragment>
    )
  }

  renderNavbarHeaderRight() {
    const { t } = this.context

    return (
      <Fragment>
        {/* <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-8 md:my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full"> */}
        <button onClick={this.onBack} title={t('NAVBAR.TOOLTIP.BACK')} className="my-0 btn-icon-label-round text-white bg-red active:bg-red-darker w-full">
          {/* <CrossIcon className="fill-current text-white" /><span className="md:hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span> */}
          <CrossIcon className="fill-current text-white" /><span className="hidden whitespace-nowrap">{t('NAVBAR.NEW')}</span>
        </button>
      </Fragment>
    )
  }

  renderLocaleSelection () {
    const { t, translationService } = this.context
    const locales = translationService.getLocales()
    const title = t('SCREENS.SETTINGS.LOCALE_TITLE')

    return (
      <div>
        <h2 className="text-xl sm:text-3xl text-gray font-bold my-4">{ title }</h2>
        <div className="select">
        <select value={this.state.locale} onChange={this.onLocaleChange} className="input w-full md:w-1/2">
          { locales.map((locale, i) => { return (
            <option value={locale} key={`locale-${i}`}>{ t(`LOCALES.${locale}`) }</option>
          )})}

        </select>
        </div>
      </div>
    )
  }

  onBack () {
    const { history } = this.props
    history.push('/reports/')
  }

  onLocaleChange (e) {
    const { target } = e
    const { value: locale } = target
    const { translationService } = this.context

    translationService.setLocale(locale)
    translationService.saveLocale()

    this.setState({ locale })
    window.location.reload();
  }
}

SettingsScreen.contextType = Context

export default SettingsScreen
