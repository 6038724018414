import React, { Component, Fragment } from 'react'
import { differenceInSeconds, differenceInDays, addSeconds, addDays, format, isBefore, startOfDay, endOfDay, isToday, isYesterday } from 'date-fns'
import Slider from 'rc-slider'
import mediaIcon from '../assets/iconic/media.svg'
import mediaStepIcon from '../assets/iconic/media-step.svg'
import 'rc-slider/assets/index.css'
import './HistoryView.scss'

const createSliderWithTooltip = Slider.createSliderWithTooltip
const SliderWithTooltip = createSliderWithTooltip(Slider)

class HistoryView extends Component {
  static SLIDER_MIN = 0
  static SLIDER_MAX = 96
  static SLIDER_STEP = 1
  static PLAYBACK_INTERVAL = 1000 * 0.5 // in ms

  constructor (props) {
    super(props)
    const { initialTimespan } = props

    this.state = {
      playing: false
    }

    this.timespans = [
      {
        label: '24h',
        value: '24h',
        checked: initialTimespan === '24h'
      }
    ]

    this.timespan = initialTimespan

    this.playbackHandle = null

    // day range to be displayed
    const { fromDate, toDate } = this.props
    this.minDate = startOfDay(fromDate)
    this.maxDate = endOfDay(toDate)
    this.nDays = differenceInDays(this.maxDate, this.minDate) + 1

    // slider values that correspond to from and to dates
    this.minSliderValue = this.sliderValue(fromDate)
    this.maxSliderValue = this.sliderValue(toDate)

    // bind methods so they can be used like functions
    this.onTimespanSelected = this.onTimespanSelected.bind(this)
    this.onSliderValueChange = this.onSliderValueChange.bind(this)
    this.startPlaying = this.startPlaying.bind(this)
    this.stopPlaying = this.stopPlaying.bind(this)
    // this.onStartStop = this.onStartStop.bind(this)
    this.onRewind = this.onRewind.bind(this)
    this.playback = this.playback.bind(this)
    this.onShowAll = this.onShowAll.bind(this)
    this.onShowMovie = this.onShowMovie.bind(this)
    this.sliderTipFormatter = this.sliderTipFormatter.bind(this)
  }

  render () {
    const { playing } = this.state
    const { showTimeline, showMovie, playbackTime, t } = this.props
    const sliderValue = this.sliderValue(playbackTime)
    const playbackDateFormatted = format(playbackTime, 'DD.MM.')
    const playbackTimeFormatted = format(playbackTime, 'HH:mm')

    const allActiveClass = !showMovie ? "btn-switch-fluid text-white bg-red active:bg-red-darker rounded-l-md"
      : "btn-switch-fluid text-gray-lighter bg-white active:bg-gray-lightest border border-gray-lightest shadow-md active:border-2 rounded-l-md"
    const movieActiveClass = showMovie ? "btn-switch-fluid text-white bg-red active:bg-red-darker rounded-r-md"
      : "btn-switch-fluid text-gray-lighter bg-white active:bg-gray-lightest border border-gray-lightest shadow-md active:border-2 rounded-r-md"

    const mediaState = playing ? 'pause' : 'play'

    // slider

    const tipProps = {
        visible:true
    }

    const { fromDate, toDate } = this.props
    const percentageFromDateMarker = this.percentage(fromDate)
    const percentageToDateMarker = this.percentage(toDate)
    const styleFromDateMarker = {
      left: `${percentageFromDateMarker}%`
    }
    const styleToDateMarker = {
      left: `${percentageToDateMarker}%`
    }
    const styleRange = {
      left: `${percentageFromDateMarker}%`,
      right: `${100 - percentageToDateMarker}%`
    }

    const marks = {}

    const { reports } = this.props
    for (let report of reports) {
      const date = new Date(report.createdAt)
      const value = this.sliderValue(date)
      marks[value] = {
        label: ''
      }
    }

    const pauseButtonClass = playing ? '' : 'hidden'
    const playButtonClass = playing ? 'hidden' : ''

    return (
            <div className="history-view container mx-auto md:flex md:pt-12 px-4">
            {
                showTimeline && (
                        <Fragment>
                          <div className='md:w-1/3'>
                          <div className="tabs is-toggle">
                              <ul className="flex">
                                  <li>
                                      <button onClick={this.onShowAll} className={allActiveClass}>
                                          <span>{ t('SCREENS.HISTORY.SHOW_ALL') }</span>
                                      </button>
                                  </li>
                                  <li>
                                      <button onClick={this.onShowMovie} className={movieActiveClass}>
                                          <span>{ t('SCREENS.HISTORY.SHOW_MOVIE') }</span>
                                      </button>
                                  </li>
                              </ul>
                          </div></div>
                          { showMovie && (
                            // <div className="md:w-2/3">
                            <div className="w-full md:space-x-4 flex flex-row flex-wrap md:flex-nowrap">
                              <div className="history-view__buttons buttons w-full md:w-1/4 md:text-right mt-4 md:mt-0">
                                <button className="history-view__button" onClick={this.onRewind}>
                                  <img data-src={mediaStepIcon} data-direction="backward" className="iconic" alt="rewind" />
                                </button>
                                <button className={`history-view__button ${pauseButtonClass}`} onClick={this.stopPlaying}>
                                  <img data-src={mediaIcon} data-state="pause" className="iconic" alt="pause" />
                                </button>
                                <button className={`history-view__button ${playButtonClass}`} onClick={this.startPlaying}>
                                  <img data-src={mediaIcon} data-state="play" className="iconic" alt="play" />
                                </button>
                              </div>
                              <SliderWithTooltip
                                  tipFormatter={this.sliderTipFormatter}
                                  tipProps={tipProps}
                                  className="history-view__slider w-full md:w-3/4"
                                  min={HistoryView.SLIDER_MIN}
                                  max={HistoryView.SLIDER_MAX}
                                  step={HistoryView.SLIDER_STEP}
                                  value={sliderValue}
                                  onChange={this.onSliderValueChange}
                                  marks={marks}
                              >
                                <div className="history-view__legend">
                                  <div className="history-view__limit" style={styleFromDateMarker}></div>
                                  <div className="history-view__limit" style={styleToDateMarker}></div>
                                  <div className="history-view__days">
                                    {this.renderDays()}
                                  </div>
                                  <div className="history-view__range has-text-success" style={styleRange}>24h</div>
                                </div>
                              </SliderWithTooltip>
                            </div>
                          ) }

                        </Fragment>
                )
            }  </div>
    )
  }

  renderDays () {
    const { t } = this.props

    const days = []
    for (let i = 0; i < this.nDays; i++) {
      const currentDate = addDays(this.minDate, i)
      const dateFormatted = formatDate(currentDate, t)
      days.push(
        <div key={`day-${i}`} className="history-view__day">{dateFormatted}</div>
      )
    }

    return days
  }

  componentDidMount () {
    window.IconicJS().inject('.history-view img.iconic')
  }

  componentDidUpdate () {
    window.IconicJS().inject('.history-view img.iconic')
  }

  componentWillUnmount () {
    this.stopPlaying()
  }

  // event listener for timespan selection (radio buttons)
  onTimespanSelected (radioButtons) {
    radioButtons.forEach((radioButton) => {
      if (radioButton.checked) {
        this.timespan = radioButton.value
        if (this.props.onTimespanSelected) {
          this.props.onTimespanSelected(radioButton.value)
        }
      }
    })
  }

  // event listener for slider value changes
  onSliderValueChange (value) {
    const { setPlaybackTime, fromDate, toDate } = this.props
    let newPlaybackTime = this.playbackTime(value)
    if (isBefore(newPlaybackTime, fromDate)) newPlaybackTime = fromDate
    if (isBefore(toDate, newPlaybackTime)) newPlaybackTime = toDate
    setPlaybackTime(newPlaybackTime)

    this.stopPlaying()
  }

  onRewind () {
    this.stopPlaying()
    const { fromDate, setPlaybackTime } = this.props
    setPlaybackTime(fromDate)
  }

  startPlaying () {
    this.setState({ playing: true })
    this.startPlayback()
  }

  stopPlaying () {
    this.setState({ playing: false })
    this.stopPlayback()
  }

  playback () {
    const { playbackTime, setPlaybackTime, toDate } = this.props
    const playbackStep = this.playbackStep(this.timespan)
    const newPlaybackTime = addSeconds(playbackTime, playbackStep)
    if (isBefore(newPlaybackTime, toDate)) {
      setPlaybackTime(newPlaybackTime)
    } else {
      this.stopPlaying()
      setPlaybackTime(toDate)
    }
  }

  startPlayback () {
    this.stopPlayback()

    this.playbackHandle = setInterval(this.playback, HistoryView.PLAYBACK_INTERVAL)
  }

  stopPlayback () {
    if (this.playbackHandle) {
      clearInterval(this.playbackHandle)
    }
  }

  setSliderValue (value) {
    this.setState({ sliderValue: value })
  }

  // converts playback time to slider value
  sliderValue (playbackTime) {
    const value = this.percentage(playbackTime) * (HistoryView.SLIDER_MAX - HistoryView.SLIDER_MIN) / 100.0
    return Math.round(value)
  }

  percentage (playbackTime) {
    return 100.0 * differenceInSeconds(playbackTime, this.minDate) / differenceInSeconds(this.maxDate, this.minDate)
  }

  // converts slider value to playback time
  playbackTime (sliderValue) {
    // const { fromDate, toDate } = this.props
    const diffSeconds = differenceInSeconds(this.maxDate, this.minDate) *
    (sliderValue - HistoryView.SLIDER_MIN) /
    (HistoryView.SLIDER_MAX - HistoryView.SLIDER_MIN)
    const playbackTime = addSeconds(this.minDate, diffSeconds)
    return playbackTime
  }

  // determine playback step (in seconds) from timespan
  playbackStep (timespan) {
    const map = {
      '3h': 3600 * 0.05,
      '12h': 3600 * 0.25,
      '24h': 3600 * 0.5,
      '48h': 3600,
      '72h': 3600 * 1.5
    }

    return map[timespan]
  }

  onShowAll () {
    const { setShowMovie } = this.props
    this.stopPlaying()
    setShowMovie(false)
  }

  onShowMovie () {
    const { setShowMovie } = this.props
    setShowMovie(true)
  }

  sliderTipFormatter (value) {
    const { playbackTime } = this.props
    const playbackDateFormatted = format(playbackTime, 'DD.MM.')
    const playbackTimeFormatted = format(playbackTime, 'HH:mm')
    return `${playbackDateFormatted} ${playbackTimeFormatted}`
  }
}

function formatDate (date, t) {
  if (isYesterday(date)) {
    return t('HELPERS.YESTERDAY')
  } else if (isToday(date)) {
    return t('HELPERS.TODAY')
  } else {
    return format(date, 'dddd, MM.DD')
  }
}

export default HistoryView
