import React from 'react'
import ActivityIndicator from './ActivityIndicator'
import splashImage from '../assets/splash.svg'

function LoadingView(props) {
    const { showSplash } = props
    return (
        <div className="">
            {showSplash && <div className=''>
                <img src={splashImage} alt="ZAMG Logo" className='md:w-1/2 pt-32 px-4 mx-auto' />
            </div>}

            <div>
                <ActivityIndicator />
            </div>
        </div>
    )
}

export default LoadingView
