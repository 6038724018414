import React, { Component } from 'react'
import { addSeconds, subSeconds, isBefore , isAfter } from 'date-fns'
import ReportsMapView from './ReportsMapView'
import ReportsListView from './ReportsListView'
import HistoryView from './HistoryView'
import { isOnline } from '../lib/utils/network'
import { timespanStringToSeconds } from '../lib/utils/time'
import './ReportsView.scss'


class ReportsView extends Component {
  constructor (props) {
    super(props)

    this.onReportSelected = this.onReportSelected.bind(this)
    this.onRegionChangeComplete = this.onRegionChangeComplete.bind(this)
    this.onUserLocationChange = this.onUserLocationChange.bind(this)
    this.onTimespanSelected = this.onTimespanSelected.bind(this)
    this.onWarningLayerToggle = this.onWarningLayerToggle.bind(this)

    const { region } = props
    this.loadReports(region)
  }

  render () {
    const {
      showList,
      showHistory,
      showMovie,
      setShowMovie,
      reports,
      region,
      timespan,
      fromDate, toDate,
      playbackTime,
      setPlaybackTime,
      reportPresentation, t, locale,
      className,
      warningStatusVisible,
      fetchWarnings
    } = this.props

   //  const heightMap = showList ? 0 : '100%'

    let mapReports = reports
    if (!showList && showHistory && showMovie) { // filter for history mode and map view only
      const timeWindow = timewindow(timespan)
      mapReports = filterReportsByTime(reports, playbackTime, timeWindow)
    }

    return (
      <div className={`reports-view ${ className || ''} ${ showList ? 'reports-view--show-list' : 'reports-view--show-map'}`}>
        {
          !showList && showHistory &&
          <div className="mt-20 md:mt-24 py-8 bg-white">
            <HistoryView
              initialTimespan={timespan}
              onTimespanSelected={this.onTimespanSelected}
              fromDate={fromDate}
              toDate={toDate}
              playbackTime={playbackTime}
              setPlaybackTime={setPlaybackTime}
              showTimeline={!showList}
              showMovie={showMovie}
              setShowMovie={setShowMovie}
              t={t}
              reports={reports}
            />
          </div>
        }
        <div className="reports-view__map-container">
          <ReportsMapView
            className="reports-view__map"
            region={region}
            reports={mapReports}
            reportPresentation={reportPresentation}
            fetchWarnings={fetchWarnings}
            t={t}
            onRegionChangeComplete={(e) => this.onRegionChangeComplete(e)}
            onReportSelected={this.onReportSelected}
            onUserLocationChange={(e) => this.onUserLocationChange(e)}
            warningStatusVisible={warningStatusVisible}
            onWarningLayerToggle={this.onWarningLayerToggle}
          />
        </div>

        <ReportsListView
          className="reports-view__list"
          // showList={showList}
          reports={reports}
          reportPresentation={reportPresentation}
          t={t}
          locale={locale}
          onReportSelected={this.onReportSelected}
        />
      </div>
    )
  }

  onReportSelected (report) {
    if (this.props.onReportSelected) {
      this.props.onReportSelected(report)
    }
  }

  onRegionChangeComplete (region) {
    const { showList } = this.props
    if (showList) return

    const { setRegion, regionService } = this.props

    setRegion(region)
    regionService.save(region)

    if (!isOnline()) {
      // TODO display message: offline
      return
    }

    this.loadReports(region)
  }

  onUserLocationChange (event) {
    const { setUserLocation } = this.props
    const { coords, timestamp } = event
    setUserLocation({ coords, timestamp })
  }

  onWarningLayerToggle() {
    if (this.props.onWarningLayerToggle) {
      this.props.onWarningLayerToggle()
    }
  }

  loadReports (region) {
    const { fetchReports } = this.props
    fetchReports({ region })
  }

  onTimespanSelected (timespan) {
    const { setTimespan, setFromDate, setToDate, playbackTime, setPlaybackTime, fetchReports } = this.props
    setTimespan(timespan)

    const toDate = new Date()
    const timespanInSeconds = timespanStringToSeconds(timespan)
    const fromDate = subSeconds(toDate, timespanInSeconds)
    setFromDate(fromDate)
    setToDate(toDate)

    if (isBefore(playbackTime, fromDate)) {
      setPlaybackTime(fromDate)
    }

    fetchReports({ fromDate, toDate })
  }
}

function filterReportsByTime (reports, time, timeWindow) {
  const timeWindowStart = subSeconds(time, timeWindow / 2)
  const timeWindowEnd = addSeconds(time, timeWindow / 2)
  const filteredReports = reports.filter((report) => {
    const date = new Date(report.createdAt)
    return isAfter(date, timeWindowStart) && isBefore(date, timeWindowEnd)
  })
  return filteredReports
}

function timewindow (timespan) {
  const map = {
    // timewindow values are in seconds
    '3h': 3600 / 8,
    '12h': 3600 / 2,
    '24h': 3600 * 2,
    '48h': 3600 * 2,
    '72h': 3600 * 3
  }

  return map[timespan]
}

export default ReportsView
