import Storage from 'react-native-storage'

class AppService {
  constructor () {
    this.storage = new Storage({
      size: 1000,
      storageBackend: window.localStorage,
      defaultExpires: 1000 * 3600 * 24 * 30 * 3,
      enableCache: true,
      sync: {
      }
    })
  }

  async hasAcceptedGDPR () {
      let hasAcceptedGDPR = false
      try {
        hasAcceptedGDPR = await this.storage.load({
            key: 'GDPRAccepted'
        })
      } catch (e) {
      } finally {
        return hasAcceptedGDPR
      }
  }

  async setHasAcceptedGDPR (value) {
    return this.storage.save({
      key: 'GDPRAccepted',
      data: value
    })
  }

  async loadAllReportsFromStorage () {
    let reports = []

    try {
      reports = await this.storage.getAllDataForKey('report')

      // determine maxId
      let maxId = this.maxId
      reports.forEach((report) => {
        if (report.localId > maxId) {
          maxId = report.localId
        }
      })
      this.maxId = maxId

      // sort reports
      const sortedReports = reports.sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1
        if (a.createdAt < b.createdAt) return 1
        return 0
      })

      reports = sortedReports
      this.setReports(reports)
    } catch (e) {
    }

    return reports
  }

  async add (report) {
    this.setReports([
      report,
      ...this.getReports()
    ])

    return this.saveToStorage(report)
  }

  async remove (localId) {
    const reports = this.getReports()
    const index = reports.findIndex((report) => {
      return report.localId === localId
    })

    if (index < 0) return

    this.setReports([
      ...this.getReports().slice(0, index),
      ...this.getReports().slice(index + 1)
    ])

    return this.removeFromStorage(localId)
  }
  // private

  async saveToStorage (report) {
    const id = report.localId || ++this.maxId
    const reportData = Object.assign({}, report, {
      localId: id
    })
    return this.storage.save({
      key: 'report',
      id,
      data: reportData
    })
  }

  async removeFromStorage (localId) {
    this.storage.remove({
      key: 'report',
      id: localId
    })
  }
}

export default AppService
