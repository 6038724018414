import { Control } from 'ol/control.js'

const WarningLayerControl = (function (Control) {
  function WarningLayerControl (opt_options) {
    const options = opt_options || {}

    const button = document.createElement('button')
    button.innerHTML = 'W'
    this.element = document.createElement('div')
    this.element.className = 'warning-layer-toggle ol-unselectable ol-control'
    this.element.appendChild(button)
    this.element.style.top = '65pt'
    this.element.style.left = '0.5em'
    this.active = false

    Control.call(this, {
      element: this.element,
      target: options.target
    })

    button.addEventListener('click', () => {
      this.active = !this.active
      this.renderActive(this.active)
      if (this.handleWarningLayerToggle) this.handleWarningLayerToggle()
    }, false)
  }

  if (Control) WarningLayerControl.__proto__ = Control
  WarningLayerControl.prototype = Object.create(Control && Control.prototype)
  WarningLayerControl.prototype.constructor = WarningLayerControl

  WarningLayerControl.prototype.setVisible = function(visible) {
    if (visible && this.element.classList.contains('warning-layer-toggle--hidden')) {
      this.element.classList.remove('warning-layer-toggle--hidden')
    } else if (!visible && !this.element.classList.contains('warning-layer-toggle--hidden')) {
      this.element.classList.add('warning-layer-toggle--hidden')
    }
  }

  WarningLayerControl.prototype.renderActive = function(active) {
    if (active && !this.element.classList.contains('warning-layer-toggle--active')) {
      this.element.classList.add('warning-layer-toggle--active')
    } else if (!active && this.element.classList.contains('warning-layer-toggle--active')) {
      this.element.classList.remove('warning-layer-toggle--active')
    }
  }

  return WarningLayerControl
}(Control))

export default WarningLayerControl
