import React from 'react'
// import './ActivityIndicator.scss'

function ActivityIndicator() {
    return (
        <span className="activity-indicator"></span>
    )
}

export default ActivityIndicator
