import React, { Component } from 'react'
import { Map, Marker } from '../lib/Map'

class ReportsMapView extends Component {
  constructor (props) {
    super(props)

    const { region } = props

    this.state = {
      region
    }

    // this.map = null // ref for map

    // this.handleMoveend = this.handleMoveend.bind(this)
    this.onRegionChangeComplete = this.onRegionChangeComplete.bind(this)
    this.onUserLocationChange = this.onUserLocationChange.bind(this)
    this.onWarningLayerToggle = this.onWarningLayerToggle.bind(this)
  }

  componentWillReceiveProps ({ region }) {
    // const viewport = region2viewport(region)
    if (region !== this.props.region) {
      this.setState({ region })
    }
  }

  render () {
    const { className, warningStatusVisible, fetchWarnings } = this.props
    const markers = []
    const len = this.props.reports.length
    for (let i = 0; i < len; i++) {
      const report = this.props.reports[len - 1 - i]
      const marker = this.renderMarker(report, i)
      markers.push(marker)
    }

    return (
      <Map
        region={this.state.region}
        showsUserLocation
        showsMyLocationButton
        zoomEnabled
        zoomControlEnabled
        onRegionChangeComplete={this.onRegionChangeComplete}
        onUserLocationChange={this.onUserLocationChange}
        className={`reports-map ${className || ''}`}
        warningStatusVisible={warningStatusVisible}
        onWarningLayerToggle={this.onWarningLayerToggle}
        fetchWarnings={fetchWarnings}
      >
        {markers}
      </Map>
    )
  }

  renderMarker (report, index) {
    const { reportPresentation } = this.props
    const { latitude, longitude, eventType } = report
    const image = reportPresentation.getImageForReport(report)
    const coordinate = { latitude, longitude }
    return (
      <Marker
        key={index}
        coordinate={coordinate}
        image={image}
        onPress={() => {
          if (this.props.onReportSelected) {
            this.props.onReportSelected(report)
          }
        }}
      />
    )
  }

  onRegionChangeComplete (region) {
    this.setState({ region })

    if (this.props.onRegionChangeComplete) {
      this.props.onRegionChangeComplete(region)
    }
  }

  onWarningLayerToggle() {
    if (this.props.onWarningLayerToggle) {
      this.props.onWarningLayerToggle()
    }
  }

  onUserLocationChange (e) {
    if (this.props.onUserLocationChange) {
      this.props.onUserLocationChange(e)
    }
  }
}

export default ReportsMapView
